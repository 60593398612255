import React from "react";
import "./HeaderComponent.scss";
import { Nav, Navbar } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import UserService from "../../../services/UserService";
import LoginPng from '../../../assets/images/logo.png';


export default function HeaderComponent() {
  const history = useHistory();


  function navigateTo(url) {
    history.push(url);
  }
  function goBack() {
    navigateTo('/patient/dashboard')
  }
  function logOut() {
    history.push('/');
    UserService.doLogout()
  }
  return (
    <div className="container-header">
      <Navbar collapseOnSelect expand="lg" variant="dark">
        <img
          src={LoginPng}
          alt=""
          width="50"
          height="50"
          className="color d-inline-block align-top auto-width" />
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto color">
            <Nav.Link onClick={(e) => goBack()}>Accueil</Nav.Link>
            {/* <Nav.Link href="/patient/profile">Mon compte</Nav.Link> */}
            {/* <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">
              Another action
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">
              Separated link
            </NavDropdown.Item>
          </NavDropdown> */}
          </Nav>
          <Nav className="color">
            {/* <Nav.Link href="/patient/pratique">Info pratiques</Nav.Link> */}
            <Nav.Link eventKey={2} onClick={logOut}>
              Déconnexion
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}