import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  useParams,
  useHistory
} from "react-router-dom";
import "./Resume.scss";
import axiosInstance from '../../../services/httpInterceptor'
import { frenchDate } from '../../../shared/tools/formatDate'
// import MesGraph from './MesGraph';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { FaRegHourglass, FaCheckCircle } from 'react-icons/fa';

import LoginPng from '../../../assets/images/logo.png';
const API_URL = process.env.REACT_APP_URL;
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '2%',
    background: 'white'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  table: {
    width: '90%',
    margin: 'auto',
    background: '#ecfdf9'
  }
}));

const TraitementStatusMapping = {
  2: 'Done',
  1: 'InProgress',
  0: 'Later',
}
const momentTranslate = {
  'Morning': 'Matin',
  'Afternoon': 'A-midi',
  'Night': 'Soir'
}
export default function Resume() {
  const [benif, setBenif] = useState({});
  const [maladiLst, setMaladiList] = useState([]);

  const [mesuresList, setmMsuresList] = useState([]);
  let { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    const url = `${API_URL}/benificiares/${id}?patient=${localStorage.patient}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        const benif = result[0];
        setBenif(benif)
      });
  }, []);
  useEffect(() => {
    const url = `${API_URL}/maladi-perso/benif/${id}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        setMaladiList(result);
      });
  }, []);

  useEffect(() => {
    const url = `${API_URL}/mesures/benif/${id}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        setmMsuresList(result.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        }))
      });
  }, []);

  function downloadFile() {
    const domElement = document.querySelectorAll(".report-container__body--header")
    const pdf = new jsPDF()
    domElement.forEach((element, i) => {
      html2canvas(element).then((canvas) => {
        const imageData = canvas.toDataURL('image/jpeg')
        pdf.addImage(imageData, 'JPEG', 0, 0, 200, 300)
        const isSelectionLast = domElement.length === i + 1
        if (isSelectionLast)
          pdf.save('file.pdf')
        else pdf.addPage()
      })
    });
  }
  function retour() {
    navigateTo('/patient/user/' + id)
  }

  function navigateTo(url) {
    history.push(url);
  }

  function getRecentMesureValue(type) {
    const mesuresListFilter = mesuresList.filter(item => item.type == type)
    if (mesuresListFilter.length > 0)
      return mesuresListFilter[0].value
    return '-';

  }
  return (

    <div className="report-container">

      <div className="report-container__left">
      </div>
      <div className="report-container__body" id="capture">

        <div className="download-button container-return-action" onClick={(e) => retour()}> Retour</div>
        {/* <div className="download-button container-return-action" onClick={(e) => downloadFile()}> Télécharger</div> */}
        <div className="report-container__body--header">
          <div className="logo-sihati">
            <img
              src={LoginPng}
              alt=""
              width="30"
              height="30"
            />
            MY-SIHATI
          </div>
          <div className="report-container__body--header-title"> Dossier medicale </div>
          <div className="report-container__body--header-bloc">
            <div className="report-container__body--header-cell">
              <div className="report-container__body--header-halfcell">
                <div>
                  <div className="text-center">
                    <span className="p-report text-center"> Nom prénom </span>
                  </div>
                  <div>
                    <span className="h2-module text-center"> {benif.last_name + ' ' + benif.first_name} </span>
                  </div>
                </div>
              </div>
              <div className="report-container__body--header-halfcell">
                <div>
                  <div className="text-center">
                    <span className="p-report text-center"> Date de naissance </span>
                  </div>
                  <div>
                    <span className="h2-report text-center"> {frenchDate(benif.birth_date)} </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="report-container__body--header-cell">
              <div className="div-center">
                <div className="p-report text-center"> GP Sangan </div>
                <div className="h2-report text-center"> {benif.groupe_sanguin} </div>
              </div>
              <div className="div-center">
                <div className="p-report text-center"> Tél </div>
                <div className="h2-report text-center"> {benif.cell_phone} </div>
              </div>
              <div className="div-center" >
                <div className="p-report text-center"> sex </div>
                <div className="h2-report text-center"> {benif.sex ? 'M' : 'F'} </div>
              </div>
              <div className="div-center">
                <div className="p-report text-center"> adresse </div>
                <div className="h2-report text-center"> {benif.adresse}</div>
              </div>
            </div>
          </div>

          <div className="report-container__body--comment-bloc" >
            <div className="h3-report"> Contacts urgents</div>
            {benif.urgent_contact && benif.urgent_contact.map((contact, index) => {
              return (
                <div className="report-container__body--text"> {index + 1 + ' - ' + (contact.first_name || '') + ' ' + (contact.last_name || '') + ' ' + (contact.mobile || '')}</div>
              )
            })}
          </div>




          <div className="report-container__body--content">
            <div className="report-content" >
              <div className="report-content__header"> Allergie </div>

              {
                maladiLst.filter(item => item.category === 'ALLERGIE').map((maladiPersoItem, index) => {
                  return (
                    <div className="report-content__body" >
                      <div className="report-content__body--text">
                        {maladiPersoItem.name ? maladiPersoItem.name : ''}
                      </div>
                      <div className="report-content__body--value"><FaRegHourglass>  </FaRegHourglass></div>
                    </div>
                  )
                })
              }
              <div className="report-content__footer" >
                <span > Commentaire </span> :  RAS
              </div>
            </div>
          </div>


          <div className="report-container__body--content">
            <div className="report-content" >
              <div className="report-content__header"> MALADIE </div>

              {
                maladiLst.filter(item => item.category === 'MALADIE').map((maladiPersoItem, index) => {
                  return (
                    <div className="report-content__body" >
                      <div className="report-content__body--text">
                        {maladiPersoItem.name ? maladiPersoItem.name : ''}
                      </div>
                      <div className="report-content__body--value"><FaRegHourglass>  </FaRegHourglass></div>
                    </div>
                  )
                })
              }
              <div className="report-content__footer" >
                <span> Commentaire </span> :  RAS
              </div>
            </div>
          </div>

          <div className="report-container__body--content">
            <div className="report-content" >
              <div className="report-content__header"> MESURE </div>
              <div className="report-content__body" >
                <div className="report-content__body--text">
                  Tension
                </div>
                <div className="report-content__body--value">{getRecentMesureValue(1)}</div>
              </div>


              <div className="report-content__body" >
                <div className="report-content__body--text">
                  Température
                </div>
                <div className="report-content__body--value">{getRecentMesureValue(6)}</div>
              </div>


              <div className="report-content__body" >
                <div className="report-content__body--text">
                  Taille
                </div>
                <div className="report-content__body--value">{getRecentMesureValue(7)}</div>
              </div>


              <div className="report-content__body" >
                <div className="report-content__body--text">
                  Poids
                </div>
                <div className="report-content__body--value">{getRecentMesureValue(8)}</div>
              </div>
              <div className="report-content__footer" >
                <span > Commentaire </span> :  RAS
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="report-container__right">
      </div>
    </div>

  );
}