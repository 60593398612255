import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { FaTrash } from 'react-icons/fa';

import axiosInstance from '../../../services/httpInterceptor'
import "./urgentContact.scss";
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '470px',
    width: '500px',
    maxWidth: '80%',
    maxHeight: '80%',
    padding: 0
  }
};
const API_URL = process.env.REACT_APP_URL;

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
export default function MaladiPerso(props) {
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(props.data.name);
  const [date, setDate] = useState(props.data.date || (new Date()).toISOString().substr(0, 10));
  const [time, setTime] = useState(props.data.time);
  const [dateStart, setDateStart] = useState(props.data.date_start_validity || (new Date()).toISOString().substr(0, 10));
  const [dateEnd, setDateEnd] = useState(props.data.date_end_validity || (new Date()).toISOString().substr(0, 10));
  const [comment, setComment] = useState(props.data.comment);
  const [attachements, setAttachements] = useState(props.data.attachements);

  const classes = useStyles();
  function closeModal() {
    props.onChange(true);
  }

  function handleSubmit(event) {
    setLoading(true);
    if (props.benif) {
      const id = (props.data && props.data._id) ? props.data._id : null;
      const dataToSend = {
        _id: id,
        benificiaire: props.benif,
        date,
        time,
        name,
        comment,
        date_start_validity: dateStart,
        date_end_validity: dateEnd,
        attachements,
      }
      const url = `${API_URL}/doc-administratif`;
      axiosInstance.post(url, dataToSend).then(response => response.data)
        .then((result) => {
          const maladId = result._id;
          sendFiles(maladId)
          closeModal()
        }
        );
    }
  }

  function uploadFile(event) {
    const file = event[0];
    if (file.size > 5000000) {
      toast.error('fichier trés large');
      return
    }
    const array = []
    for (let i = 0; i < event.length; i++) {
      array.push(event[i])
    }
    setAttachements(array);
  }
  function sendFiles(maladId) {
    if (attachements) {
      if (attachements && attachements.length > 0 && attachements[0]._id) { closeModal(); }
      else if (attachements.length == 0) { closeModal(); }
      else {
        const formData = new FormData();
        for (var x = 0; x < attachements.length; x++) {
          formData.append("file", attachements[x], attachements[x].name);
        }
        const url = `${API_URL}/doc-administratif/${maladId}/benif/${props.benif}/upload/${attachements[0].name}?patient=${localStorage.patient}`
        axiosInstance.post(url, formData).then(response => response.data)
          .then((result) => {
            closeModal();
          }
          );
      }
    } else {
      closeModal()
    }
  }

  function deleteFile(e, file, index) {
    setAttachements(attachements.filter(item => item.name !== file.name));
  }
  // function handleUrgentContact(i, field, value) {
  //   if(urgentContact.length == 0) {

  //     console.log('urgentContact D ', urgentContact)
  //     setUrgentContact([{last_name: ''}, {last_name: ''}, {last_name: ''}])}
  //   console.log('urgentContact ', urgentContact)

  //   console.log('urgentContact ddd', urgentContact)
  //   urgentContact[i][field] = value
  //   setUrgentContact(urgentContact)
  // };
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={closeModal.bind(this)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal__header">
        <div className="modal__header-title">
          {
            !props.data._id ? 'Nouveau Document' : `Mise à jour d'un Document`
          }
        </div>
        <div className="modal__header-close">
        </div>
      </div>

      <div className="modal__body">

        <div className="modal__body-description">
          Les champs obligatoires sont suivi par une *
        </div>


        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <div className={classes.form} >
              <div className={'content-modal'}>
                <Container component="main" maxWidth="xs">
                  <div className={classes.paper}>
                    <div className={classes.form} >
                      <div className={'content-modal'}>

                        <TextField margin="normal" fullWidth label="Nom" name="lastName" value={name} onChange={event => setName(event.target.value)} />
                        <TextField id="date" label="Date" type="date" value={date}
                          onChange={event => setDate(event.target.value)}
                          InputLabelProps={{ shrink: true }} />
                        <TextField id="date" label="L'heure" type="time" value={time} className={'input-min-width margin-left'}
                          onChange={event => setTime(event.target.value)}
                          InputLabelProps={{ shrink: true }} />

                        <TextField id="date" label="date début" type="date" value={dateStart}
                          onChange={event => setDateStart(event.target.value)}
                          InputLabelProps={{ shrink: true }} />
                        <TextField id="date" label="Date de fin" className={"margin-left"} type="date" value={dateEnd}
                          onChange={event => setDateEnd(event.target.value)}
                          InputLabelProps={{ shrink: true }} />
                        <TextField margin="normal" fullWidth label="Commentaire" name="firstName" value={comment} onChange={event => setComment(event.target.value)} />
                        {
                          (attachements && attachements.length > 0) ?
                            (attachements).map((item, index) => {
                              return (<div key={'attachements' + index}> {item.name} <FaTrash onClick={(e) => deleteFile(e, item, index)}></FaTrash></div>);
                            })
                            : null
                        }
                        <div className={'drag-container'}>
                          <input
                            type="file"
                            onChange={(e) => uploadFile(e.target.files)} />

                          <span>Parcourir mon ordinateur (Max 5M)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>
              </div>

            </div>
          </div>
        </Container>

      </div>

      <div className={'footer-modal'}>
        <Button
          variant="contained"
          color="default"
          className={classes.submit}
          onClick={closeModal}
        > Annuler </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
        >
          {!props.data._id ? 'Ajouter' : `Mise à jour`}</Button>
      </div>
    </Modal>
  );
}