import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { FaAngleDown, FaBackward, FaPlus, FaEdit, FaTrashAlt } from 'react-icons/fa';
import {
  BrowserRouter as Router,
  useParams
} from "react-router-dom";
import WarningMessage from '../../../shared/component/WarningMessage'
import { useHistory } from "react-router-dom";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import "./mesRadios.scss";
import axiosInstance from '../../../services/httpInterceptor'
import HeaderComponent from '../header/headerComponent'
import RadioAdd from './RadioAdd'
import DisplayFile from '../commun/displayFile'
import AttachementComponenet from '../../shared/AttachementComponenet'
import { frenchDate } from '../../../shared/tools/formatDate'
import EmptyList from '../../../shared/component/EmptyList';
import { FaCheckCircle, FaRegHourglass } from 'react-icons/fa';
const API_URL = process.env.REACT_APP_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '2%',
    background: 'white'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  table: {
    width: '90%',
    margin: 'auto',
    background: '#ecfdf9'
  }
}));

const StatusMapping = {
  1: 'Done',
  0: 'Later',
}
const TypeImageMapping = {
  1: 'Radiographie',
  2: 'Scanner',
  3: 'Scintigraphie',
  4: 'Tomographie',
  5: 'Échographie',
  6: 'Électro-encéphalographie',
  7: 'Magnétoencéphalographie',
  8: 'Imagerie',
  9: 'résonance magnétique',
  10: 'IRM'
}
export default function MesRadios() {

  const [sequence, setsequence] = React.useState(0);
  const [isFileOpen, setIsFileOpen] = useState(false);
  const [fileJson, setFileJson] = useState('');
  let { id } = useParams();
  const history = useHistory()
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [radioList, setRadioList] = React.useState([]);
  const [displayedRadioList, setdisplayedRadioList] = React.useState([]);

  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [radio, setRadio] = useState({});
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');


  useEffect(() => {
    const url = `${API_URL}/benificiares/${id}?patient=${localStorage.patient}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        const benif = result[0];
        setFirstName(benif.first_name)
        setLastName(benif.last_name)
      }
      );
    refreshList();
  }, []);


  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    const filteredData = radioList.filter(data =>
      data.radio_status === StatusMapping[newValue])
    setdisplayedRadioList(filteredData)
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  function goBack() {
    navigateTo('/patient/user/' + id)
  }
  function navigateTo(url) {
    history.push(url);
  }


  function refreshList() {
    const url = `${API_URL}/radios/benif/${id}?patient=${localStorage.patient}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        setsequence(result.length)
        setRadioList(result);
        const filteredData = result.filter(data =>
          data.radio_status === StatusMapping[value])
        setdisplayedRadioList(filteredData)
      }
      );
  }
  function addRadio() {
    setRadio({})
    setIsOpenAdd(true)
  }
  function onChangeAdd(value) {
    setIsOpenAdd(false)
    refreshList();
  }

  function supprimerRadio(analyse) {
    setRadio(analyse)
    setIsOpenWarning(true)
  }
  function editRadio(analyse) {
    setRadio(analyse)
    setIsOpenAdd(true)
  }

  function onCloseWarning(value) {
    setIsOpenWarning(false)
  }
  function onConfirm(value) {
    setIsOpenWarning(false);
    const url = `${API_URL}/radios/${radio._id}?patient=${localStorage.patient}`;
    axiosInstance.delete(url).then(response => response.data)
      .then((result) => {
        refreshList()
      }
      );
    refreshList()
  }
  function onChangeFile(value) {
    setIsFileOpen(false)
  }
  function downloadFile(file) {
    setFileJson(file)
    setIsFileOpen(true)
  }
  function updateAttachement(file, consultation) {
    const analyseId = consultation._id;
    sendFiles(analyseId, [file]);
  }
  function sendFiles(analyseId, fileList) {
    if (fileList) {

      const formData = new FormData();
      for (var x = 0; x < fileList.length; x++) {
        formData.append("file", fileList[x], fileList[x].name);
      }
      const url = `${API_URL}/radios/${analyseId}/benif/${id}/upload/${fileList[0].name}?patient=${localStorage.patient}`
      axiosInstance.post(url, formData).then(response => response.data)
        .then((result) => {
          refreshList();
        }
        );
    }

  }
  return (
    <div className="container-wrapper">
      <HeaderComponent></HeaderComponent>
      <div className="container-return-action" onClick={(e) => goBack()}>
        <FaBackward>  </FaBackward> {'  '} &nbsp;&nbsp;Retour à l'accueil
      </div>
      {
        isOpenAdd ?
          <RadioAdd
            sequence={sequence}
            isOpen={isOpenAdd}
            onChange={onChangeAdd}
            benif={id}
            radio={radio}
          ></RadioAdd>
          : null
      }
      {
        isFileOpen ?
          <DisplayFile
            onChangeFile={onChangeFile}
            isOpen={isFileOpen}
            fileJson={fileJson}
            benif={id}
          ></DisplayFile>
          : null
      }
      <WarningMessage
        onCloseWarning={onCloseWarning}
        isOpenWarning={isOpenWarning}
        onConfirm={onConfirm}>
      </WarningMessage>
      <div className="container-body">



        <div className="container-right-actions" onClick={(e) => addRadio()}>
          <div className="container-right-actions_bloc-left">
            <div className="container-right-actions_bloc-left_inside">
              <FaPlus>  </FaPlus>
            </div></div>Ajouter une Image
          {/* <div className="btn-action"> <FaPlus>  </FaPlus>Prendre RDV</div> */}
        </div>
        <div className="container-title">
          Detail des radios
        </div>
        <Tabs
          value={value}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="A venir" icon={<FaRegHourglass />} style={{ color: 'red' }} />
          <Tab label="Terminé" icon={<FaCheckCircle />} style={{ color: 'green' }} />
        </Tabs>
        <div className={classes.root}>
          {
            displayedRadioList.length === 0 && <EmptyList></EmptyList>
          }
          {
            displayedRadioList.map((radio, index) => {
              return (
                <Accordion expanded={expanded === radio._id} onChange={handleChange(radio._id)}>
                  <AccordionSummary
                    expandIcon={<FaAngleDown />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className='accordion__title'>{frenchDate(radio.date_prevu)}</Typography>
                    <Typography className='accordion__subtitle'>
                      {radio.laboratory}
                      &nbsp;({TypeImageMapping[radio.image_type]})
                    </Typography>
                    <Typography className='accordion__subtitle1'>{radio.maladie}</Typography>

                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='all_width'>

                      <div className="lines__footer">
                        <div className="lines__footer-action" onClick={(e) => supprimerRadio(radio)}> <FaTrashAlt>  </FaTrashAlt>Supprimer</div>
                        <div className="lines__footer-action" onClick={(e) => editRadio(radio)}> <FaEdit>  </FaEdit>Modifier</div>
                      </div>
                      <Typography className="padding2">
                        {radio.note}
                      </Typography>
                      <div className="lines">
                        <div className="lines__line"> <div className="lines__title">Titre</div><div className="lines__desc">{radio.radio_name}</div></div>
                        <div className="lines__line"> <div className="lines__title">Type d'image</div><div className="lines__desc">{TypeImageMapping[radio.image_type]}</div></div>
                        <div className="lines__line"> <div className="lines__title">Centre Imagerie</div><div className="lines__desc">{radio.laboratory}</div></div>
                        {
                          radio.ordonnance && radio.ordonnance.consultation &&
                          <div>
                            <div className="lines__line"> <div className="lines__title">Docteur Prescripteur</div><div className="lines__desc">{radio.ordonnance.consultation.medecin.last_name} {radio.ordonnance.consultation.medecin.first_name}</div></div>
                            <div className="lines__line"> <div className="lines__title">Ordonnance</div><div className="lines__desc">{radio.ordonnance.ordonnance_name}</div></div>

                          </div>

                        }
                        <div className="lines__line"> <div className="lines__title">Maladie</div><div className="lines__desc">{radio.maladie}</div></div>
                        <div className="lines__line"> <div className="lines__title">Date prise RDV</div><div className="lines__desc">{frenchDate(radio.date_prise_rdv)}</div></div>
                        {value === 0 ? <div className="lines__line"> <div className="lines__title">Date prévue</div><div className="lines__desc">{frenchDate(radio.date_prevu)}  {radio.time_prevu}</div></div> : null}
                        {value === 1 ? <div className="lines__line"> <div className="lines__title">Date réalisation</div><div className="lines__desc">{frenchDate(radio.date_rdv)} {radio.time_rdv}</div></div> : null}
                        {/* <div  className="lines__line"> <div className="lines__title">interpretation Labo</div><div className="lines__desc">{radio.interpretation_labo}</div></div>
                    <div  className="lines__line"> <div className="lines__title">interpretation Doc</div><div className="lines__desc">{radio.interpretation_medecin}</div></div> */}
                        <div className="lines__line"> <div className="lines__title">Prix</div><div className="lines__desc">{radio.price}</div></div>
                        <div className="lines__line"> <div className="lines__title">Commentaire</div><textarea className="lines__desc" value={radio.comment}></textarea></div>

                        {
                          radio.radio_status === 'Done' ?
                            <div className="result__container" >
                              <div className="result__title"> Resultat </div>
                              <div>
                                <div className="result__exam-name">Attachement </div>
                                {/* {
                                  radio.attachements.map((exam, index) => {
                                    return (<div className="download-file result__exam-name-comment" onClick={(e) => downloadFile(exam)}> Télécharger le radio </div>)
                                  })
                                } */}
                                {
                                  radio.attachements.length > 0 ?
                                    radio.attachements.map((exam, index) => {
                                      return (<div className="download-file lines__desc" onClick={(e) => downloadFile(exam)}> Télécharger le document </div>)
                                    })
                                    : <AttachementComponenet saveFile={(e) => updateAttachement(e, radio)}></AttachementComponenet>
                                }
                                <div className="result__exam-name"> interpretation Docteur prescripteur</div>
                                <div className="lines__line"> <textarea className="lines__desc" value={radio.result_interpretation}></textarea></div>
                                <div className="result__exam-name"> interpretation Centre de Radiologie</div>
                                <div className="lines__line"> <textarea className="lines__desc" value={radio.interpretation_labo}></textarea></div>

                                <div className="result__exam-name"> conclusion </div>
                                <div className="lines__line"> <textarea className="lines__desc" value={radio.result_conclusion}></textarea></div>


                              </div>

                            </div>
                            : null
                        }

                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>

              )
            })
          }
        </div>
      </div>
    </div>
  );
}