import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { FaAngleDown, FaBackward, FaPlus, FaTrashAlt } from 'react-icons/fa';
import {
  BrowserRouter as Router,
  useParams
} from "react-router-dom";
import { useHistory } from "react-router-dom";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import "./mesTraitements.scss";
import HeaderComponent from '../header/headerComponent'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';
import WarningMessage from '../../../shared/component/WarningMessage'

import AttachementComponenet from '../../shared/AttachementComponenet'
import { FaCheckCircle, FaRegHourglass, FaEdit } from 'react-icons/fa';
import axiosInstance from '../../../services/httpInterceptor'
import TraitementAdd from './TraitementAdd'
import DisplayFile from '../commun/displayFile'
import { frenchDate } from '../../../shared/tools/formatDate'
// import MesGraph from './MesGraph';

import EmptyList from '../../../shared/component/EmptyList'
const API_URL = process.env.REACT_APP_URL;
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '2%',
    background: 'white'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  table: {
    width: '90%',
    margin: 'auto',
    background: '#ecfdf9'
  }
}));

const TraitementStatusMapping = {
  2: 'Done',
  1: 'InProgress',
  0: 'Later',
}
const momentTranslate = {
  'Morning': 'Matin',
  'Afternoon': 'A-midi',
  'Night': 'Soir'
}
export default function MesTraitements() {
  const [isFileOpen, setIsFileOpen] = useState(false);
  const [fileJson, setFileJson] = useState('');
  let { id } = useParams();
  const history = useHistory()
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [consultationList, setConsultationList] = React.useState([]);
  const [displayedConsultationList, setdisplayedConsultationList] = React.useState([]);

  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [traitement, setTraitement] = useState({});
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [sequence, setsequence] = React.useState(0);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const url = `${API_URL}/benificiares/${id}?patient=${localStorage.patient}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        const benif = result[0];
        setFirstName(benif.first_name)
        setLastName(benif.last_name)
      }
      );
    refreshList();
  }, []);


  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    const filteredData = consultationList.filter(data =>
      data.traitement_status === TraitementStatusMapping[newValue])
    setdisplayedConsultationList(filteredData)
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  function goBack() {
    navigateTo('/patient/user/' + id)
  }
  function navigateTo(url) {
    history.push(url);
  }

  function openGraph(e, benif) {
    setIsOpen(true)
  }
  function onChange(value) {
    setIsOpen(false)
  }
  function refreshList() {
    const url = `${API_URL}/traitements/benif/${id}?patient=${localStorage.patient}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        setsequence(result.length)
        setConsultationList(result);
        const filteredData = result.filter(data =>
          data.traitement_status === TraitementStatusMapping[value])
        setdisplayedConsultationList(filteredData)
      }
      );
  }
  function addTraitement() {
    setTraitement({})
    setIsOpenAdd(true)
  }
  function onChangeAdd(value) {
    setIsOpenAdd(false)
    refreshList();
  }
  function supprimerTraitement(traitement) {
    setTraitement(traitement)
    setIsOpenWarning(true)
  }
  function editTraitement(traitement) {
    setTraitement(traitement)
    setIsOpenAdd(true)
  }
  function downloadFile(file) {
    setFileJson(file)
    setIsFileOpen(true)
  }
  function onCloseWarning(value) {
    setIsOpenWarning(false)
  }
  function onConfirm(value) {
    setIsOpenWarning(false);
    const url = `${API_URL}/traitements/${traitement._id}?patient=${localStorage.patient}`;
    axiosInstance.delete(url).then(response => response.data)
      .then((result) => {
        refreshList()
      }
      );
    refreshList()
  }
  function onChangeFile(value) {
    setIsFileOpen(false)
  }

  function updateAttachement(file, consultation) {
    const analyseId = consultation._id;
    sendFiles(analyseId, [file]);
  }
  function sendFiles(analyseId, fileList) {
    if (fileList) {

      const formData = new FormData();
      for (var x = 0; x < fileList.length; x++) {
        formData.append("file", fileList[x], fileList[x].name);
      }
      const url = `${API_URL}/traitements/${analyseId}/benif/${id}/upload/${fileList[0].name}?patient=${localStorage.patient}`
      axiosInstance.post(url, formData).then(response => response.data)
        .then((result) => {
          refreshList();
        }
        );
    }

  }
  return (
    <div className="container-wrapper">
      <HeaderComponent></HeaderComponent>
      <div className="container-return-action" onClick={(e) => goBack()}>
        <FaBackward>  </FaBackward> {'  '} &nbsp;&nbsp;Retour à l'accueil
      </div>
      {
        isOpenAdd ?
          <TraitementAdd
            sequence={sequence}
            isOpen={isOpenAdd}
            onChange={onChangeAdd}
            traitement={traitement}
            benif={id}
          ></TraitementAdd>
          : null
      }
      {
        isFileOpen ?
          <DisplayFile
            onChangeFile={onChangeFile}
            isOpen={isFileOpen}
            fileJson={fileJson}
            benif={id}
          ></DisplayFile>
          : null
      }
      <WarningMessage
        onCloseWarning={onCloseWarning}
        isOpenWarning={isOpenWarning}
        onConfirm={onConfirm}>
      </WarningMessage>
      <div className="container-body">


        <div className="container-right-actions" onClick={(e) => addTraitement()}>
          <div className="container-right-actions_bloc-left">
            <div className="container-right-actions_bloc-left_inside">
              <FaPlus>  </FaPlus>
            </div></div>Ajouter un traitement
          {/* <div className="btn-action"> <FaPlus>  </FaPlus>Prendre RDV</div> */}
        </div>
        <div className="container-title">
          Detail des traitements
        </div>
        <Tabs
          value={value}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          centered
        >

          <Tab label="A venir" icon={<FaRegHourglass />} style={{ color: 'red' }} />
          <Tab label="En Cours" icon={<FaCheckCircle />} style={{ color: 'orange' }} />
          <Tab label="Terminé" icon={<FaCheckCircle />} style={{ color: 'green' }} />
        </Tabs>
        <div className={classes.root}>
          {
            displayedConsultationList.length === 0 && <EmptyList></EmptyList>
          }
          {
            displayedConsultationList.map((traitement, index) => {
              return (
                <Accordion expanded={expanded === traitement._id} onChange={handleChange(traitement._id)}>
                  <AccordionSummary
                    expandIcon={<FaAngleDown />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className='accordion__title'> {frenchDate(traitement.start_date)} - {frenchDate(traitement.end_date)} </Typography>
                    <Typography className='accordion__subtitle'>

                      {traitement.ordonnance && traitement.ordonnance.consultation ? traitement.ordonnance.consultation.consultation_maladie : ""}

                    </Typography>
                    <Typography className='accordion__subtitle1'>durée restante :{traitement.rest_duration}</Typography>

                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='all_width'>

                      <div className="lines__footer">
                        <div className="lines__footer-action" onClick={(e) => supprimerTraitement(traitement)}> <FaTrashAlt>  </FaTrashAlt>Supprimer</div>
                        <div className="lines__footer-action" onClick={(e) => editTraitement(traitement)}> <FaEdit>  </FaEdit>Modifier</div>
                      </div>
                      <Typography className="padding2">
                        {traitement.note}
                      </Typography>
                      <div className="lines">
                        <div className="lines__line"> <div className="lines__title">Titre du traitement</div><div className="lines__desc">{traitement.traitement_name}</div></div>
                        <div className="lines__line"> <div className="lines__title">Maladie</div><div className="lines__desc">{traitement.pathologie}</div></div>
                        {
                          traitement.ordonnance ? <div>
                            <div className="lines__line"> <div className="lines__title">Ordonnance</div><div className="lines__desc">{traitement.ordonnance.ordonnance_name}</div></div>
                            <div className="lines__line"> <div className="lines__title">Date Ordonnance</div><div className="lines__desc">{frenchDate(traitement.ordonnance.date_rdv)}</div></div>
                            <div className="lines__line"> <div className="lines__title">Medecin Presc.</div><div className="lines__desc">{traitement.ordonnance.consultation ? traitement.ordonnance.consultation.medecin.last_name : ''} {traitement.ordonnance.consultation ? traitement.ordonnance.consultation.medecin.first_name : ''}</div></div>
                          </div> : null
                        }
                        {/* <div className="lines__line"> <div className="lines__title">Date prise RDV</div><div className="lines__desc">{frenchDate(traitement.date_prise_rdv)}</div></div> */}

                        <div className="lines__line"> <div className="lines__title">date début</div><div className="lines__desc">{frenchDate(traitement.start_date)}</div></div>
                        <div className="lines__line"> <div className="lines__title">Date fin</div><div className="lines__desc" >{frenchDate(traitement.end_date)}</div></div>
                        <div className="lines__line"> <div className="lines__title">Durée traitement</div><div className="lines__desc" >{traitement.traitement_duration}</div></div>
                        <div className="lines__line"> <div className="lines__title">Durée restante</div><div className="lines__desc"> {traitement.rest_duration}</div></div>
                        <div className="lines__line"> <div className="lines__title">Commentaire</div><textarea className="lines__desc" value={traitement.comment}></textarea></div>
                        <div className="lines__line"> <div className="lines__title">Document</div>
                          {
                            traitement.attachements.length > 0 ?
                              traitement.attachements.map((exam, index) => {
                                return (<div className="download-file lines__desc" onClick={(e) => downloadFile(exam)}> Télécharger le Document </div>)
                              })
                              : <AttachementComponenet saveFile={(e) => updateAttachement(e, traitement)}></AttachementComponenet>
                          }
                        </div>
                        {
                          <div className="result__container" >
                            <div className="lines__line"> <div className="lines__title">Commentaire Pers. traitement</div><textarea className="lines__desc" value={traitement.comment_personnel_traitment}></textarea></div>
                            <div className="lines__line"> <div className="lines__title">commentaire medecin</div><textarea className="lines__desc" value={traitement.interpretation_dr}></textarea></div>
                            <div className="result__title"> Sous traitements </div>
                            {traitement.subTraitments ? traitement.subTraitments.map((subTraitment, index) => {
                              return (
                                <div>
                                  <div className="lines__line"> <div className="lines__title">Sous traitement {index + 1}</div><div className="lines__title">{subTraitment.sub_traitement_type} {subTraitment.sub_traitement_name}</div></div>
                                  <div className="lines__line"> <div className="lines__title">Posologie </div><div className="lines__desc">{subTraitment.sub_traitement_posologie}</div></div>
                                  <div className="lines__line"> <div className="lines__title">date début </div><div className="lines__desc">{frenchDate(subTraitment.sub_traitement_start_date)}</div></div>
                                  <div className="lines__line"> <div className="lines__title">Date fin </div><div className="lines__desc">{frenchDate(subTraitment.sub_traitement_end_date)}</div></div>
                                  <div className="lines__line"> <div className="lines__title">Nbr de prise / jours </div><div className="lines__desc">{subTraitment.sub_traitement_time_per_day}</div></div>
                                  <div className="lines__line"> <div className="lines__title">Durée passée </div><div className="lines__desc">{subTraitment.sub_traitement_duration_passed}</div></div>
                                  <div className="lines__line"> <div className="lines__title">Durée restante</div><div className="lines__desc">{subTraitment.sub_traitement_duration_reste}</div></div>

                                  <div>
                                    <Table className={classes.table} aria-label="simple table">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Date prise</TableCell>
                                          <TableCell align="right">Période</TableCell>
                                          <TableCell align="right">valeur</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      {subTraitment.result.map((row, index) => {
                                        return (
                                          <TableBody>
                                            <TableRow key={row.name}>
                                              <TableCell align="">{frenchDate(row.date_prise)}</TableCell>
                                              <TableCell align="right">{momentTranslate[row.moment]}</TableCell>
                                              <TableCell align="right">{row.value}</TableCell>
                                            </TableRow>
                                          </TableBody>
                                        )
                                      })}
                                    </Table>
                                  </div>
                                </div>)
                            }) : null}
                          </div>
                        }
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>

              )
            })
          }
        </div>
      </div>
    </div>
  );
}