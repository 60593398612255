export const analyseConfig = [
  {
    category: 'Hématologie',
    subCategories: [{
      subCategoryName: 'NUMERATION GLOBULAIRE',
      results: [
        {
          code: 'HMTC',
          label: 'Hématies',
          unit: 'Tera/L',
          reference_min: '4.28',
          reference_max: '5.79',
          historique: ''
        },
        {
          code: 'VGM',
          label: 'V.G.M.',
          unit: 'fl',
          reference_min: '78.0',
          reference_max: '97.0',
          historique: ''
        }
      ]
    },
    {
      subCategoryName: 'FORMULE LEUCOCYTAIRE',
      results: [
        {
          code: 'Monocytes',
          label: 'Monocytes',
          unit: 'Tera/L',
          reference_min: '4.28',
          reference_max: '5.79',
          historique: ''
        },
        {
          code: 'Lymphocytes',
          label: 'Lymphocytes',
          unit: 'fl',
          reference_min: '78.0',
          reference_max: '97.0',
          historique: ''
        }
      ]
    },
    {
      subCategoryName: 'NUMERATION PLAQUETTAIRE',
      results: [
        {
          code: 'Plaquettes',
          label: 'Plaquettes',
          unit: 'Tera/L',
          reference_min: '161',
          reference_max: '398',
          historique: ''
        }
      ]
    }
    ],
  },
  {
    category: 'Hémostase',
    subCategories: [{
      subCategoryName: 'TAUX DE PROTHROMBINE',
      results: [
        {
          code: 'INR',
          label: 'INR',
          unit: '%',
          reference_min: '4.28',
          reference_max: '5.79',
          historique: ''
        },
        {
          code: 'TP',
          label: 'T.P',
          unit: 'fl',
          reference_min: '78.0',
          reference_max: '97.0',
          historique: ''
        }
      ]
    },
    {
      subCategoryName: 'TEMPS DE CEPHALINE ACTIVEE - APTT SP',
      results: [
        {
          code: 'Ratio M/T',
          label: 'RatioMT',
          unit: 'Tera/L',
          reference_min: '4.28',
          reference_max: '5.79',
          historique: ''
        },
        {
          code: 'TCA',
          label: 'TCA',
          unit: 'fl',
          reference_min: '78.0',
          reference_max: '97.0',
          historique: ''
        }
      ]
    }
    ],
  }, {
    category: 'Biochimie',
    subCategories: [{
      subCategoryName: 'EVALUATION DU DEBIT DE FILTRATION GLOMERULAIRE (D.F.G)',
      results: [
        {
          code: 'Créatinine',
          label: 'Créatinine',
          unit: '%',
          reference_min: '65',
          reference_max: '104',
          historique: ''
        },
        {
          code: "DFG CKD-EPI",
          label: "DFG selon l'équation CKD-EPI",
          unit: 'fl',
          reference_min: '78.0',
          reference_max: '97.0',
          historique: ''
        },
        {
          code: "DFG afro-américain",
          label: 'DFG pour sujets de type afro-américain',
          unit: 'fl',
          reference_min: '78.0',
          reference_max: '97.0',
          historique: ''
        },
        {
          code: "DFG MDRD",
          label: "DFG selon l'équation MDRD",
          unit: 'fl',
          reference_min: '78.0',
          reference_max: '97.0',
          historique: ''
        },
        {
          code: "TRANSAMINASE",
          label: 'TRANSAMINASE ASAT (S.G.O.T)',
          unit: 'fl',
          reference_min: '78.0',
          reference_max: '97.0',
          historique: ''
        },
        {
          code: "PHOSPHATASES",
          label: 'PHOSPHATASES ALCALINES',
          unit: 'fl',
          reference_min: '78.0',
          reference_max: '97.0',
          historique: ''
        },
        {
          code: "GAMMA-GLUTAMYL",
          label: '"GAMMA-GLUTAMYL TRANSFERASE',
          unit: 'fl',
          reference_min: '78.0',
          reference_max: '97.0',
          historique: ''
        },
        {
          code: "BILIRUBINE",
          label: 'BILIRUBINE totale',
          unit: 'fl',
          reference_min: '78.0',
          reference_max: '97.0',
          historique: ''
        },
        {
          code: "LACTATE",
          label: 'LACTATE DEHYDROGENASE (L.D.H.)',
          unit: 'fl',
          reference_min: '78.0',
          reference_max: '97.0',
          historique: ''
        },
        {
          code: "LIPASE",
          label: 'LIPASE',
          unit: 'fl',
          reference_min: '78.0',
          reference_max: '97.0',
          historique: ''
        },
        {
          code: "CHOLESTEROL",
          label: 'CHOLESTEROL total',
          unit: 'fl',
          reference_min: '78.0',
          reference_max: '97.0',
          historique: ''
        },
        {
          code: "TRIGLYCERIDES",
          label: 'TRIGLYCERIDES',
          unit: 'fl',
          reference_min: '78.0',
          reference_max: '97.0',
          historique: ''
        }
      ]
    }
    ],
  }
]