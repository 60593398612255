import React, { useState, useEffect } from "react";
import "./Dashboard.scss";
import DashboardDoctor from './DashboardDoctor'
import DashboardPatient from './DashboardPatient'

export default function PatientDashboard() {
  const [patientType, setpatientType] = useState("");

  useEffect(() => {
    setpatientType(localStorage.profile_corps_medicale)
  }, []);

  return (
    <div className="container-wrapper">
      {
        patientType == "SPECIALIST" || patientType == "GENERALIST" ||
          patientType == "SPECIALISTE" || patientType == "GENERALISTE" || patientType == "LABORATOIRE" ?
          <DashboardDoctor></DashboardDoctor>
          : <DashboardPatient></DashboardPatient>
      }
    </div>
  );
}