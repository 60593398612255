import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { FaAngleDown, FaBackward, FaPlus, FaEdit, FaTrashAlt } from 'react-icons/fa';
import {
  BrowserRouter as Router,
  useParams
} from "react-router-dom";
import { useHistory } from "react-router-dom";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import "./mesHospitalisations.scss";
import HeaderComponent from '../header/headerComponent'
import { useTranslation } from 'react-i18next';
import WarningMessage from '../../../shared/component/WarningMessage'

import AttachementComponenet from '../../shared/AttachementComponenet'
import { FaCheckCircle, FaRegHourglass } from 'react-icons/fa';
import EmptyList from '../../../shared/component/EmptyList'
import axiosInstance from '../../../services/httpInterceptor'
import HospitalisationAdd from './HospitalisationAdd'
import DisplayFile from '../commun/displayFile'
import { frenchDate } from '../../../shared/tools/formatDate'
// import MesGraph from './MesGraph';

const API_URL = process.env.REACT_APP_URL;
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '2%',
    background: 'white'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  table: {
    width: '90%',
    margin: 'auto',
    background: '#ecfdf9'
  }
}));

const HospitalisationStatusMapping = {
  2: 'Done',
  1: 'InProgress',
  0: 'Later',
}
const momentTranslate = {
  'Morning': 'Matin',
  'Afternoon': 'A-midi',
  'Night': 'Soir'
}
export default function MesHospitalisations() {
  const [isFileOpen, setIsFileOpen] = useState(false);
  const [fileJson, setFileJson] = useState('');
  let { id } = useParams();
  const history = useHistory()
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [consultationList, setConsultationList] = React.useState([]);
  const [displayedConsultationList, setdisplayedConsultationList] = React.useState([]);

  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [hospitalisation, setHospitalisation] = useState({});
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const url = `${API_URL}/benificiares/${id}?patient=${localStorage.patient}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        const benif = result[0];
        setFirstName(benif.first_name)
        setLastName(benif.last_name)
      }
      );
    refreshList();
  }, []);


  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    const filteredData = consultationList.filter(data =>
      data.hospitalisation_status === HospitalisationStatusMapping[newValue])
    setdisplayedConsultationList(filteredData)
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  function goBack() {
    navigateTo('/patient/user/' + id)
  }
  function navigateTo(url) {
    history.push(url);
  }

  function openGraph(e, benif) {
    setIsOpen(true)
  }
  function onChange(value) {
    setIsOpen(false)
  }
  function refreshList() {
    const url = `${API_URL}/hospitalisations/benif/${id}?patient=${localStorage.patient}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        setConsultationList(result);
        const filteredData = result.filter(data =>
          data.hospitalisation_status === HospitalisationStatusMapping[value])
        setdisplayedConsultationList(filteredData)
      }
      );
  }
  function addHospitalisation() {
    setHospitalisation({})
    setIsOpenAdd(true)
  }
  function onChangeAdd(value) {
    setIsOpenAdd(false)
    refreshList();
  }
  function supprimerHospitalisation(hospitalisation) {
    setHospitalisation(hospitalisation)
    setIsOpenWarning(true)
  }
  function editHospitalisation(hospitalisation) {
    setHospitalisation(hospitalisation)
    setIsOpenAdd(true)
  }
  function downloadFile(file) {
    setFileJson(file)
    setIsFileOpen(true)
  }
  function onCloseWarning(value) {
    setIsOpenWarning(false)
  }
  function onConfirm(value) {
    setIsOpenWarning(false);
    const url = `${API_URL}/hospitalisations/${hospitalisation._id}?patient=${localStorage.patient}`;
    axiosInstance.delete(url).then(response => response.data)
      .then((result) => {
        refreshList()
      }
      );
    refreshList()
  }
  function onChangeFile(value) {
    setIsFileOpen(false)
  }

  function updateAttachement(file, consultation) {
    const analyseId = consultation._id;
    sendFiles(analyseId, [file]);
  }
  function sendFiles(analyseId, fileList) {
    if (fileList) {

      const formData = new FormData();
      for (var x = 0; x < fileList.length; x++) {
        formData.append("file", fileList[x], fileList[x].name);
      }
      const url = `${API_URL}/hospitalisations/${analyseId}/benif/${id}/upload/${fileList[0].name}?patient=${localStorage.patient}`
      axiosInstance.post(url, formData).then(response => response.data)
        .then((result) => {
          refreshList();
        }
        );
    }

  }
  return (
    <div className="container-wrapper">
      <HeaderComponent></HeaderComponent>
      <div className="container-return-action" onClick={(e) => goBack()}>
        <FaBackward>  </FaBackward> {'  '} &nbsp;&nbsp;Retour à l'accueil
      </div>
      {
        isOpenAdd ?
          <HospitalisationAdd
            isOpen={isOpenAdd}
            onChange={onChangeAdd}
            hospitalisation={hospitalisation}
            benif={id}
          ></HospitalisationAdd>
          : null
      }
      {
        isFileOpen ?
          <DisplayFile
            onChangeFile={onChangeFile}
            isOpen={isFileOpen}
            fileJson={fileJson}
            benif={id}
          ></DisplayFile>
          : null
      }
      <WarningMessage
        onCloseWarning={onCloseWarning}
        isOpenWarning={isOpenWarning}
        onConfirm={onConfirm}>
      </WarningMessage>
      <div className="container-body">

        <div className="container-right-actions" onClick={(e) => addHospitalisation()}>
          <div className="container-right-actions_bloc-left">
            <div className="container-right-actions_bloc-left_inside">
              <FaPlus>  </FaPlus>
            </div></div>Ajouter une hospitalisation
          {/* <div className="btn-action"> <FaPlus>  </FaPlus>Prendre RDV</div> */}
        </div>
        <div className="container-title">
          Detail des hospitalisations
        </div>
        <Tabs
          value={value}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          centered
        >

          <Tab label="A venir" icon={<FaRegHourglass />} style={{ color: 'red' }} />
          <Tab label="En Cours" icon={<FaCheckCircle />} style={{ color: 'orange' }} />
          <Tab label="Terminé" icon={<FaCheckCircle />} style={{ color: 'green' }} />
        </Tabs>
        <div className={classes.root}>
          {
            displayedConsultationList.length === 0 && <EmptyList></EmptyList>
          }
          {
            displayedConsultationList.map((hospitalisation, index) => {
              return (
                <Accordion expanded={expanded === hospitalisation._id} onChange={handleChange(hospitalisation._id)}>
                  <AccordionSummary
                    expandIcon={<FaAngleDown />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className='accordion__title'>{frenchDate(hospitalisation.start_date)} - {frenchDate(hospitalisation.end_date)} </Typography>
                    <Typography className='accordion__subtitle'>
                      {hospitalisation.centre}
                      &nbsp;{hospitalisation.maladie}
                    </Typography>
                    <Typography className='accordion__subtitle1'>Opération: {hospitalisation.operation ? 'Oui' : 'Non'}</Typography>

                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='all_width'>

                      <div className="lines__footer">
                        <div className="lines__footer-action" onClick={(e) => supprimerHospitalisation(hospitalisation)}> <FaTrashAlt>  </FaTrashAlt>Supprimer</div>
                        <div className="lines__footer-action" onClick={(e) => editHospitalisation(hospitalisation)}> <FaEdit>  </FaEdit>Modifier</div>
                      </div>
                      <Typography className="padding2">
                        {hospitalisation.note}
                      </Typography>
                      <div className="lines">
                        <div className="lines__line"> <div className="lines__title">date début</div><div className="lines__desc">{frenchDate(hospitalisation.start_date)}</div></div>
                        <div className="lines__line"> <div className="lines__title">Date fin</div><div className="lines__desc" >{frenchDate(hospitalisation.end_date)}</div></div>
                        <div className="lines__line"> <div className="lines__title">Maladie</div><div className="lines__desc" >{hospitalisation.maladie}</div></div>
                        <div className="lines__line"> <div className="lines__title">Centre hospitalisation</div><div className="lines__desc" >{hospitalisation.centre}</div></div>
                        <div className="lines__line"> <div className="lines__title">Service</div><div className="lines__desc" >{hospitalisation.hospitalisation_service}</div></div>
                        <div className="lines__line"> <div className="lines__title">Addresse</div><div className="lines__desc" >{hospitalisation.addresse}</div></div>
                        <div className="lines__line"> <div className="lines__title">Tel</div><div className="lines__desc" >{hospitalisation.phone_number}</div></div>
                        <div className="lines__line"> <div className="lines__title">Fax</div><div className="lines__desc" >{hospitalisation.fax}</div></div>
                        <div className="lines__line"> <div className="lines__title">Email</div><div className="lines__desc" >{hospitalisation.email_address}</div></div>
                        <div className="lines__line"> <div className="lines__title">Contact</div><div className="lines__desc" >{hospitalisation.contacts}</div></div>
                        <div className="lines__line"> <div className="lines__title">Opération</div><div className="lines__desc" >{hospitalisation.operation ? 'Oui' : 'Non'}</div></div>
                        <div className="lines__line"> <div className="lines__title">Date opération</div><div className="lines__desc" >{frenchDate(hospitalisation.date_opertation)}</div></div>
                        <div className="lines__line"> <div className="lines__title">Durée du séjour</div><div className="lines__desc" >{hospitalisation.duration}</div></div>
                        <div className="lines__line"> <div className="lines__title">Compte rendu</div><textarea className="lines__desc" value={hospitalisation.comment_hospitalier}></textarea></div>
                        <div className="lines__line"> <div className="lines__title">Commentaire patient</div><textarea className="lines__desc" value={hospitalisation.comment}></textarea></div>
                        <div className="lines__line"> <div className="lines__title">Document</div>
                          {
                            hospitalisation.attachements.length > 0 ?
                              hospitalisation.attachements.map((exam, index) => {
                                return (<div className="download-file lines__desc" onClick={(e) => downloadFile(exam)}> Télécharger le Document </div>)
                              })
                              : <AttachementComponenet saveFile={(e) => updateAttachement(e, hospitalisation)}></AttachementComponenet>
                          }
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>

              )
            })
          }
        </div>
      </div>
    </div>
  );
}