import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { FaAngleDown, FaBackward, FaPlus, FaPoll, FaEdit, FaTrashAlt } from 'react-icons/fa';
import {
  BrowserRouter as Router,
  useParams
} from "react-router-dom";
import { useHistory } from "react-router-dom";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import "./mesAnalyses.scss";
import HeaderComponent from '../header/headerComponent'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import WarningMessage from '../../../shared/component/WarningMessage'


import AttachementComponenet from '../../shared/AttachementComponenet'
import { FaCheckCircle, FaRegHourglass } from 'react-icons/fa';
import EmptyList from '../../../shared/component/EmptyList'
import DisplayFile from '../commun/displayFile'
import axiosInstance from '../../../services/httpInterceptor'
import AnalyseAdd from './AnalyseAdd'
import MesGraph from './MesGraph';
import { frenchDate } from '../../../shared/tools/formatDate'

const API_URL = process.env.REACT_APP_URL;
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '2%',
    background: 'white'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  table: {
    width: '90%',
    margin: 'auto',
    background: '#ecfdf9'
  }
}));

const StatusMapping = {
  1: 'Done',
  0: 'Later',
}
export default function MesAnalyses() {
  const [isFileOpen, setIsFileOpen] = useState(false);
  const [isGraphOpen, setIsGraphOpen] = useState(false);
  const [codeGraph, setCodeGraph] = useState(false);
  const [fileJson, setFileJson] = useState('');
  let { id } = useParams();
  const history = useHistory()
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [consultationList, setConsultationList] = React.useState([]);
  const [displayedConsultationList, setdisplayedConsultationList] = React.useState([]);

  const [sequence, setsequence] = React.useState(0);
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [analyse, setAnalyse] = useState({});
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [graphData, setGraphData] = useState([]);
  const [benif, setBenif] = useState({});

  useEffect(() => {
    const url = `${API_URL}/benificiares/${id}?patient=${localStorage.patient}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        const benif = result[0];
        setFirstName(benif.first_name)
        setLastName(benif.last_name)
        setBenif(benif);
      }
      );
    refreshList();
  }, []);


  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    const filteredData = consultationList.filter(data =>
      data.analyse_status === StatusMapping[newValue])
    setdisplayedConsultationList(filteredData)
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  function goBack() {
    navigateTo('/patient/user/' + id)
  }
  function navigateTo(url) {
    history.push(url);
  }

  function openGraph(e, row) {
    const graphData = [];
    setCodeGraph(row)
    consultationList.map(consultation => {
      if (consultation.results)
        consultation.results.map(categ => {
          categ.subCategories.map(subCateg => {
            subCateg.results.map(resSubCateg => {
              if (resSubCateg.code === row.code)
                graphData.push({ date: consultation.date_rdv || consultation.date_prevu, value: resSubCateg.value })
            })
          })
        })
    })
    graphData.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });
    setGraphData(graphData)
    setIsGraphOpen(true)
  }
  function onChange(value) {
    setIsOpen(false)
  }
  function refreshList() {
    const url = `${API_URL}/analyses/benif/${id}?patient=${localStorage.patient}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        setsequence(result.length)
        setConsultationList(result);
        const filteredData = result.filter(data =>
          data.analyse_status === StatusMapping[value])
        setdisplayedConsultationList(filteredData)
      }
      );
  }
  function addAnalyse() {
    setAnalyse({})
    setIsOpenAdd(true)
  }
  function onChangeAdd(value) {
    setIsOpenAdd(false)
    refreshList();
  }
  function supprimerAnalyse(analyse) {
    setAnalyse(analyse)
    setIsOpenWarning(true)
  }
  function editAnalyse(analyse) {
    setAnalyse(analyse)
    setIsOpenAdd(true)
  }
  function downloadFile(file) {
    setFileJson(file)
    setIsFileOpen(true)
  }
  function onChangeFile(value) {
    setIsFileOpen(false)
  }
  function onChangeGraph(value) {
    setIsGraphOpen(false)
  }
  function onCloseWarning(value) {
    setIsOpenWarning(false)
  }
  function onConfirm(value) {
    setIsOpenWarning(false);
    const url = `${API_URL}/analyses/${analyse._id}?patient=${localStorage.patient}`;
    axiosInstance.delete(url).then(response => response.data)
      .then((result) => {
        refreshList()
      }
      );
    refreshList()
  }

  function updateAttachement(file, consultation) {
    const analyseId = consultation._id;
    sendFiles(analyseId, [file]);
  }
  function sendFiles(analyseId, fileList) {
    if (fileList) {

      const formData = new FormData();
      for (var x = 0; x < fileList.length; x++) {
        formData.append("file", fileList[x], fileList[x].name);
      }
      const url = `${API_URL}/analyses/${analyseId}/benif/${id}/upload/${fileList[0].name}?patient=${localStorage.patient}`
      axiosInstance.post(url, formData).then(response => response.data)
        .then((result) => {
          refreshList();
        }
        );
    }

  }
  return (
    <div className="container-wrapper">
      <HeaderComponent></HeaderComponent>
      <div className="container-return-action" onClick={(e) => goBack()}>
        <FaBackward>  </FaBackward> &nbsp;&nbsp;Retour à l'accueil
      </div>
      {
        isOpenAdd ?
          <AnalyseAdd
            sequence={sequence}
            isOpen={isOpenAdd}
            onChange={onChangeAdd}
            analyse={analyse}
            benif={id}
          ></AnalyseAdd>
          : null
      }
      {
        isFileOpen ?
          <DisplayFile
            onChangeFile={onChangeFile}
            isOpen={isFileOpen}
            fileJson={fileJson}
            benif={id}
          ></DisplayFile>
          : null
      }
      {
        isGraphOpen ?
          <MesGraph
            onChangeGraph={onChangeGraph}
            isOpen={isGraphOpen}
            graphData={graphData}
            benif={benif}
            codeGraph={codeGraph}
          ></MesGraph>
          : null
      }
      <WarningMessage
        onCloseWarning={onCloseWarning}
        isOpenWarning={isOpenWarning}
        onConfirm={onConfirm}>
      </WarningMessage>
      <div className="container-body">


        <div className="container-right-actions" onClick={(e) => addAnalyse()}>
          <div className="container-right-actions_bloc-left">
            <div className="container-right-actions_bloc-left_inside">
              <FaPlus>  </FaPlus>
            </div></div>Ajouter une Analyse
          {/* <div className="btn-action"> <FaPlus>  </FaPlus>Prendre RDV</div> */}
        </div>
        <div className="container-title">
          Detail des analyses:
        </div>

        <Tabs
          value={value}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          centered
        >

          <Tab label="A venir" icon={<FaRegHourglass />} style={{ color: 'red' }} />
          <Tab label="Terminé" icon={<FaCheckCircle />} style={{ color: 'green' }} />
        </Tabs>
        <div className={classes.root}>
          {
            displayedConsultationList.length === 0 && <EmptyList></EmptyList>
          }
          {
            displayedConsultationList.map((analyse, index) => {
              return (
                <Accordion expanded={expanded === analyse._id} onChange={handleChange(analyse._id)}>
                  <AccordionSummary
                    expandIcon={<FaAngleDown />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className='accordion__title'>{frenchDate(analyse.date_prevu)}</Typography>
                    <Typography className='accordion__subtitle'>
                      {analyse.laboratory}
                    </Typography>
                    <Typography className='accordion__subtitle1'>{analyse.ordonnance ? analyse.ordonnance.consultation.consultation_maladie : ""}</Typography>

                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='all_width'>

                      <div className="lines__footer">
                        <div className="lines__footer-action" onClick={(e) => supprimerAnalyse(analyse)}> <FaTrashAlt>  </FaTrashAlt>Supprimer</div>
                        <div className="lines__footer-action" onClick={(e) => editAnalyse(analyse)}> <FaEdit>  </FaEdit>Modifier</div>
                      </div>
                      {
                        analyse.note && <Typography className="padding2">
                          <b>Description: </b>
                          {analyse.note}
                        </Typography>
                      }

                      <div className="lines">
                        <div className="lines__line"> <div className="lines__title">Laboratoire</div><div className="lines__desc">{analyse.laboratory}</div></div>
                        <div className="lines__line"> <div className="lines__title">Titre</div><div className="lines__desc">{analyse.analyse_name}</div></div>
                        {
                          analyse.ordonnance ? <div>
                            <div className="lines__line"> <div className="lines__title">Medecin Presc.</div><div className="lines__desc">{analyse.ordonnance && analyse.ordonnance.consultation ? analyse.ordonnance.consultation.medecin.last_name : ''} {analyse.ordonnance.consultation ? analyse.ordonnance.consultation.medecin.first_name : ''}</div></div>
                            <div className="lines__line"> <div className="lines__title">Ordonnance</div><div className="lines__desc">{analyse.ordonnance.ordonnance_name}</div></div>
                            <div className="lines__line"> <div className="lines__title">Date Ordonnance</div><div className="lines__desc">{frenchDate(analyse.ordonnance.date_rdv)}</div></div>
                          </div> : null
                        }

                        {/* <div className="lines__line"> <div className="lines__title">Date prise RDV</div><div className="lines__desc">{frenchDate(analyse.date_prise_rdv)}</div></div> */}
                        {
                          analyse.analyse_status !== 'Done' ?
                            <div className="lines__line"> <div className="lines__title">Date prevue</div><div className="lines__desc">{frenchDate(analyse.date_prevu)}</div></div>
                            : null}
                        {
                          analyse.analyse_status === 'Done' ?
                            <div className="lines__line"> <div className="lines__title">Date realisé</div><div className="lines__desc">{frenchDate(analyse.date_rdv)}</div></div>
                            : null}
                        <div className="lines__line"> <div className="lines__title">Prix</div><div className="lines__desc">{analyse.price}</div></div>
                        <div className="lines__line"> <div className="lines__title">Commentaire Patient</div><textarea className="lines__desc" value={analyse.comment}></textarea></div>


                        {
                          analyse.analyse_status === 'Done' ?
                            <div className="result__container" >
                              <div className="result__title"> Documents </div>
                              <div>
                                <div className="result__exam-name">Attachement </div>
                                {/* {
                                  analyse.attachements.map((exam, index) => {
                                    return (<div className="download-file result__exam-name-comment" onClick={(e) => downloadFile(exam)}> Télécharger l'analyse </div>)
                                  })
                                } */}
                                {
                                  analyse.attachements.length > 0 ?
                                    analyse.attachements.map((exam, index) => {
                                      return (<div className="download-file lines__desc" onClick={(e) => downloadFile(exam)}> Télécharger l'analyse </div>)
                                    })
                                    : <AttachementComponenet saveFile={(e) => updateAttachement(e, analyse)}></AttachementComponenet>
                                }

                              </div>

                            </div>
                            : null
                        }
                        {
                          analyse.analyse_status === 'Done' ?

                            <div className="result__container" >

                              <div className="result__title"> Resultat </div>

                              <div className="lines__line"> <div className="lines__title">Interpretation Labo</div><div className="lines__desc">{analyse.interpretation_labo}</div></div>
                              <div className="lines__line"> <div className="lines__title">Interpretation Doc</div><div className="lines__desc">{analyse.interpretation_medecin}</div></div>
                              {analyse.results ? analyse.results.map((category, index) => {
                                return (
                                  <div>
                                    <div className="result__category" >{category.category}</div>
                                    {category.subCategories.map((subCategory, index) => {
                                      return (
                                        <div>
                                          <div className="result__exam-name"> {subCategory.subCategoryName} </div>
                                          <div className="result__exam-name-comment"> {subCategory.comment} </div>

                                          <Table className={classes.table} aria-label="simple table">
                                            <TableHead>
                                              <TableRow>
                                                <TableCell>nom</TableCell>
                                                <TableCell align="right">valeur</TableCell>
                                                <TableCell align="right">unité</TableCell>
                                                <TableCell align="right">reference</TableCell>
                                                <TableCell align="right"></TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody> {subCategory.results.map((row) => (<TableRow key={row.name}> <TableCell align="">{row.label}</TableCell>
                                              <TableCell align="right">{row.value}</TableCell>
                                              <TableCell align="right">{row.unit}</TableCell>
                                              <TableCell align="right">{row.reference_max} - {row.reference_min} </TableCell>
                                              <TableCell>
                                                <FaPoll className="graph-button" onClick={(e) => openGraph(e, row)} >
                                                </FaPoll></TableCell> </TableRow>))} </TableBody> </Table>
                                          {/* { exam.results.map((result, index) => { return ( <div className="result__result-line"> <div>{result.label}</div> <div>{result.value} {result.unit}</div> <div>{result.reference_min}</div> <div>{result.reference_max}</div> </div> ) }) } */}
                                        </div>)
                                    })}
                                  </div>)
                              }) : null}
                            </div>
                            : null
                        }
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>

              )
            })
          }
        </div>
      </div>
    </div>
  );
}