export const RegionList = [
  "L’Oriental",
  "Marrakech-Safi",
  "Drâa-Tafilalet",
  "Fès-Meknès",
  "Guelmim-oued Noun",
  "Tanger-Tétouan-Al Hoceima",
  "Souss-Massa",
  "Casablanca-Settat",
  "Dakhla-Oued Eddahab",
  "Beni Mellal-Khénifra",
  "Rabat-Salé-Kénitra",
  "Laâyoune-Sakia Al Hamra"
]

export const ProvinceList = [
  "Oujda-Angad",
  "Nador",
  "Driouch",
  "Jerada",
  "Berkan",
  "Taourirt",
  "Guercif",
  "Figuig",
  "Marrakech",
  "Chichaoua",
  "Al Haouz",
  "Kelâa des Sraghna",
  "Essaouira",
  "Rehamna",
  "Safi",
  "Youssoufia",
  "Errachidia",
  "Ouarzazate",
  "Midelt",
  "Tinghir",
  "Zagora​",
  "Fès",
  "Meknès",
  "El Hajeb",
  "Ifrane",
  "Moulay Yacoub",
  "Sefrou",
  "Boulemane",
  "Taounate",
  "Taza",
  "Guelmim",
  "Assa-Zag",
  "Tan-Tan",
  "Sidi Ifni",
  "Tanger-Assilah",
  "M'diq-Fnideq",
  "Tétouan",
  "Fahs-Anjra",
  "Larache",
  "Al Hoceima",
  "Chefchaouen",
  "Ouazzane",
  "Agadir Ida-Ou-Tanane",
  "Inezgane-Aït Melloul",
  "Chtouka-Aït Baha",
  "Taroudannt",
  "Tiznit",
  "Tata",
  "Casablanca",
  "Mohammadia",
  "El Jadida",
  "Nouaceur",
  "Médiouna",
  "Benslimane",
  "Berrechid",
  "Settat",
  "Sidi Bennour",
  "Oued Ed-Dahab",
  "Aousserd​",
  "Béni Mellal",
  "Azilal",
  "Fquih Ben Salah",
  "Khénifra",
  "Khouribga​",
  "Rabat",
  "Salé",
  "Skhirate-Témara",
  "Kénitra",
  "Khémisset",
  "Sidi Kacem",
  "Sidi Slimane",
  "Laâyoune",
  "Boujdour",
  "Tarfaya",
  "Es-Semara"
]

export const FamilyLinkList = [
  "Père",
  "Mère",
  "Epouse",
  "Epous",
  "Fille",
  "Fils",
  "Grand-Père P",
  "Grand-Mère P",
  "Grand-Père M",
  "Grand-Mère M",
]

export const SexList = [
  "Homme",
  "Femme"
]
export const ProfilList = [
  "Medecin",
  "Laboratoire Biologique",
  "Centre Radiologie",
  "Infermier(e)",
  "Pharmacien"
]
export const maladiStatus = [
  "Début",
  "Avancé",
  "Remission",
  "Guerri"
]