import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { FaRegGrinBeamSweat } from 'react-icons/fa';
import "./WarningMessage.scss";

const API_URL = process.env.REACT_APP_URL;
const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(1, 0, 0),
  },
}));
export default function EmptyList(props) {


  return (
    <div className="empty">
      <FaRegGrinBeamSweat className="empty_icon"></FaRegGrinBeamSweat>
      <div className="empty_text">La list est vide </div>
    </div>
  )
}