import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { FaAngleDown, FaBackward, FaTrashAlt, FaEdit, FaAngleDoubleDown } from 'react-icons/fa';
import {
  BrowserRouter as Router,
  useParams
} from "react-router-dom";
import WarningMessage from '../../../shared/component/WarningMessage'
import axiosInstance from '../../../services/httpInterceptor'
import { useHistory } from "react-router-dom";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import "./mesConsultations.scss";
import HeaderComponent from '../header/headerComponent'
import ConsultationAdd from './ConsultationAdd'
import DisplayFile from '../commun/displayFile'
import { frenchDate } from '../../../shared/tools/formatDate'

import OrdonnaceAdd from '../ordonnance/OrdonnaceAdd'
import ProfileCard from '../../shared/profileCard'
import AttachementComponenet from '../../shared/AttachementComponenet'
import { FaCheckCircle, FaRegHourglass, FaPlus } from 'react-icons/fa';
import EmptyList from '../../../shared/component/EmptyList'
import DentistSVG from "../../../assets/images/svg/dentist.svg";
import DoctorSVG from "../../../assets/images/svg/doctor.svg";

const API_URL = process.env.REACT_APP_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '2%',
    background: 'white'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const StatusMapping = {
  0: 'Later',
  1: 'Done',
}

export default function MesConsultations() {

  const [isFileOpen, setIsFileOpen] = useState(false);
  const [fileJson, setFileJson] = useState('');
  let { id } = useParams();
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const history = useHistory()
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [sequence, setsequence] = React.useState(0);
  const [filter, setFilter] = React.useState('Medecin');
  const [consultationList, setConsultationList] = React.useState([]);
  const [displayedConsultationList, setdisplayedConsultationList] = React.useState([]);

  const [benif, setBenif] = useState({});
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [consultation, setConsultation] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  //inside bloc

  const [isDisplayInsodeOrdo, setisDisplayInsodeOrdo] = useState(false);
  const [isOpenOrdonnance, setisOpenOrdonnance] = useState(false);
  const [ordonnance, setOrdonnance] = useState({});
  const [ordonnanceInsideList, setordonnanceInsideList] = React.useState([]);
  const [ORdonnaceInsideSequence, setORdonnaceInsideSequence] = React.useState(0);

  useEffect(() => {
    const url = `${API_URL}/benificiares/${id}?patient=${localStorage.patient}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        const benif = result[0];
        setBenif(benif)
        setFirstName(benif.first_name)
        setLastName(benif.last_name)
      });
    refreshList();
  }, []);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    const filteredData = consultationList.filter(data =>
      data.consultation_status === StatusMapping[newValue] && data.medecin.speciality === filter)
    setdisplayedConsultationList(filteredData)
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setordonnanceInsideList([])
    setORdonnaceInsideSequence(0)
    setisDisplayInsodeOrdo(false)
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangeFilter = (panel) => (event, isExpanded) => {
    setFilter(panel);
    const filteredData = consultationList.filter(data =>
      data.consultation_status === StatusMapping[value] && data.medecin.speciality === panel)
    setdisplayedConsultationList(filteredData)
  };

  function goBack() {
    navigateTo('/patient/user/' + id)
  }
  function navigateTo(url) {
    history.push(url);
  }

  function addConsultation() {
    setConsultation({})
    setIsOpen(true)
  }

  function editConsultation(consultation) {
    setConsultation(consultation)
    setIsOpen(true)
  }

  function deleteConsultation(consultation) {
    setConsultation(consultation)
    setIsOpenWarning(true)
  }

  function refreshList() {
    const url = `${API_URL}/consultation/benif/${id}?patient=${localStorage.patient}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        setsequence(result.length)
        setConsultationList(result);
        const filteredData = result.filter(data =>
          data.consultation_status === StatusMapping[value] && data.medecin.speciality === filter)
        setdisplayedConsultationList(filteredData)
      }
      );
  }
  function onChange(value) {
    setIsOpen(false)
    refreshList();
  }
  function onChangeisOpenOrdonnance(value, consId) {
    setisOpenOrdonnance(false)
    listOrdonnanceByCons(consId)
  }
  function onChangeFile(value) {
    setIsFileOpen(false)
  }
  function onConfirm(value) {
    setIsOpenWarning(false);
    const url = `${API_URL}/consultation/${consultation._id}?patient=${localStorage.patient}`;
    axiosInstance.delete(url).then(response => response.data)
      .then((result) => {
        refreshList()
      }
      );
    refreshList()
  }
  function onCloseWarning(value) {
    setIsOpenWarning(false)
  }

  function downloadFile(file) {
    setFileJson(file)
    setIsFileOpen(true)
  }

  function listOrdonnanceByCons(consId) {
    const url = `${API_URL}/ordonnances/benif/${id}?patient=${localStorage.patient}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        setORdonnaceInsideSequence(result.length)
        const filteredData = result.filter(data => {
          console.log(data.consultation._id, consId, data.consultation._id == consId)
          return data.consultation && data.consultation._id == consId
        })
        setordonnanceInsideList(filteredData);
        setisDisplayInsodeOrdo(true)
      });
  }
  function updateInsideOrdonnance(consultation) {
    setisOpenOrdonnance(true)
    const ordonnance = {
      consultation: {
        _id: consultation._id,
        consultation_name: consultation.consultation_name
      }
    }
    setOrdonnance(ordonnance)
  }
  function updateOrdonnance(ordonnance) {
    setisOpenOrdonnance(true)
    setOrdonnance(ordonnance)
  }
  function updateAttachement(file, consultation) {
    const analyseId = consultation._id;
    sendFiles(analyseId, [file]);
  }
  function sendFiles(analyseId, fileList) {
    if (fileList) {

      const formData = new FormData();
      for (var x = 0; x < fileList.length; x++) {
        formData.append("file", fileList[x], fileList[x].name);
      }
      const url = `${API_URL}/consultation/${analyseId}/benif/${id}/upload/${fileList[0].name}?patient=${localStorage.patient}`
      axiosInstance.post(url, formData).then(response => response.data)
        .then((result) => {
          refreshList();
        }
        );
    }

  }
  return (
    <div className="container-wrapper">
      <HeaderComponent></HeaderComponent>

      <div className="container-return-action" onClick={(e) => goBack()}>
        <FaBackward>  </FaBackward> {'  '} &nbsp;&nbsp;Retour à l'accueil
      </div>
      {
        isOpen ?
          <ConsultationAdd
            onChange={onChange}
            isOpen={isOpen}
            consultation={consultation}
            sequence={sequence}
            benif={id}
          ></ConsultationAdd>
          : null
      }
      {
        isFileOpen ?
          <DisplayFile
            onChangeFile={onChangeFile}
            isOpen={isFileOpen}
            fileJson={fileJson}
            benif={id}
          ></DisplayFile>
          : null
      }
      {
        isOpenOrdonnance ?
          <OrdonnaceAdd
            sequence={ORdonnaceInsideSequence}
            onChange={onChangeisOpenOrdonnance}
            isOpen={isOpenOrdonnance}
            ordonnance={ordonnance}
            benif={id}
          ></OrdonnaceAdd>
          : null
      }
      <WarningMessage
        onCloseWarning={onCloseWarning}
        isOpenWarning={isOpenWarning}
        onConfirm={onConfirm}>
      </WarningMessage>
      <div className="container-body">
        {/* <ProfileCard
          name={firstName + " " + lastName}
          age={benif.birth_date}
          city={benif.city}
          toDo="80"
          inProgress="803"
          done="1"
        ></ProfileCard> */}


        <div className="container-right-actions" onClick={(e) => addConsultation()}>
          <div className="container-right-actions_bloc-left">
            <div className="container-right-actions_bloc-left_inside">
              <FaPlus>  </FaPlus>
            </div></div>Ajouter une consultation
          {/* <div className="btn-action"> <FaCalendarPlus>  </FaCalendarPlus>Prendre RDV</div> */}
        </div>

        <div className="container-title">
          Detail des consultations:
        </div>
        <div className="container-filters-top">
          <div className={filter === 'Medecin' ? "container-filter-top-actif" : "container-filter-top"}
            onClick={handleChangeFilter('Medecin')}>
            <img className="img-small" src={DoctorSVG} alt="Your SVG" />
            Médecin</div>
          <div className={filter === 'Dentiste' ? "container-filter-top-actif" : "container-filter-top"}
            onClick={handleChangeFilter('Dentiste')}>
            <img className="img-small" src={DentistSVG} alt="Your SVG" />Dentiste</div>
        </div>


        <Tabs
          value={value}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          centered
        >

          <Tab label="A venir" icon={<FaRegHourglass />} style={{ color: 'red' }} />
          <Tab label="Terminé" icon={<FaCheckCircle />} style={{ color: 'green' }} />
        </Tabs>
        <div className={classes.root}>
          {
            displayedConsultationList.length === 0 && <EmptyList></EmptyList>
          }
          {
            displayedConsultationList.map((consultation, index) => {
              return (
                <Accordion expanded={expanded === consultation._id} onChange={handleChange(consultation._id)}>
                  <AccordionSummary
                    expandIcon={<FaAngleDown />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className='accordion__title'>{frenchDate(consultation.date_rdv)}</Typography>
                    <Typography className='accordion__subtitle'>Dr. {consultation.medecin.first_name} {consultation.medecin.last_name}</Typography>
                    <Typography className='accordion__subtitle1'>{consultation.consultation_maladie}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='all_width'>

                      <div className="lines__footer">
                        <div className="lines__footer-action" onClick={(e) => deleteConsultation(consultation)}> <FaTrashAlt>  </FaTrashAlt>Supprimer</div>
                        <div className="lines__footer-action" onClick={(e) => editConsultation(consultation)}> <FaEdit>  </FaEdit>Modifier</div>
                      </div>
                      {
                        consultation.consultation_desc && <Typography className="padding2">
                          <b>Description: </b>
                          <span>{consultation.consultation_desc} </span>

                        </Typography>
                      }

                      <div className="lines">
                        <div className="lines__line"> <div className="lines__title">Titre</div><div className="lines__desc">{consultation.consultation_name}</div></div>
                        <div className="lines__line"> <div className="lines__title">Maladie</div><div className="lines__desc">{consultation.consultation_maladie}</div></div>
                        <div className="lines__line"> <div className="lines__title">Docteur</div><div className="lines__desc">{consultation.medecin.first_name} {consultation.medecin.last_name}</div></div>
                        <div className="lines__line"> <div className="lines__title">Date de prise de RDV</div><div className="lines__desc">{frenchDate(consultation.date_prise_rdv)}</div></div>
                        {value === 1 ? <div className="lines__line"> <div className="lines__title">Date de RDV</div><div className="lines__desc">{frenchDate(consultation.date_rdv)}  {consultation.time_rdv}</div></div> : null}
                        {value === 0 ? <div className="lines__line"> <div className="lines__title">Date de consultation</div><div className="lines__desc">{frenchDate(consultation.date_consultation)}</div></div> : null}
                        <div className="lines__line"> <div className="lines__title">Prix</div><div className="lines__desc">{consultation.price}</div></div>
                        <div className="lines__line"> <div className="lines__title">Commentaire medecin</div><textarea className="lines__desc" value={consultation.commentaire_medecin}></textarea></div>
                        <div className="lines__line"> <div className="lines__title">Commentaire</div><textarea className="lines__desc" value={consultation.commentaire}></textarea></div>

                        <div className="lines__line">
                          <div className="lines__title">Attachement</div>
                          {
                            consultation.attachements.length > 0 ?
                              consultation.attachements.map((exam, index) => {
                                return (<div className="download-file lines__desc" onClick={(e) => downloadFile(exam)}> Télécharger la  consultation </div>)
                              })
                              : <AttachementComponenet saveFile={(e) => updateAttachement(e, consultation)}></AttachementComponenet>
                          }
                        </div>
                        <div className="lines__line"> <div className="lines__title-clicked" onClick={(e) => listOrdonnanceByCons(consultation._id)}><FaAngleDoubleDown></FaAngleDoubleDown> Afficher les ordonnances</div>

                        </div>
                        {
                          isDisplayInsodeOrdo &&
                          <div className="bloc-inside">
                            {
                              <div>
                                <div className="lines__line"> <div className="lines__title-clicked" onClick={(e) => updateInsideOrdonnance(consultation)}>{'+ '} Ajouter ordonnance</div></div>
                                {ordonnanceInsideList.map((ordonnance, index) => {
                                  return (
                                    <div className="bloc-inside__item" onClick={(e) => updateOrdonnance(ordonnance)}>
                                      <span>{ordonnance.ordonnance_name} </span>
                                    </div>
                                  )
                                })}
                              </div>

                            }
                          </div>
                        }

                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>

              )
            })
          }
        </div>
      </div>
    </div>
  );
}