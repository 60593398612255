import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Modal from 'react-modal';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axiosInstance from '../../../services/httpInterceptor'
import "./urgentContact.scss";
import { FamilyLinkList, ProvinceList, RegionList, SexList } from "../../../assets/references/referenceList";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '470px',
    width: '500px',
    maxWidth: '80%',
    maxHeight: '80%',
    padding: 0
  }
};
const API_URL = process.env.REACT_APP_URL;

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
export default function UrgentContact(props) {
  const [loading, setLoading] = useState(false);
  const [consultation, setConsultation] = useState(props.benificiaire.consultation);
  const [dateRDV, setDateRDV] = useState(props.benificiaire.date_rdv || (new Date()).toISOString().substr(0, 10));
  const [ordonnanceName, setOrdonnanceName] = useState(props.benificiaire.ordonnance_name);
  const [price, setPrice] = useState(props.benificiaire.price);
  const [commentPatient, setCommentPatient] = useState(props.benificiaire.comment);
  const [comment, setComment] = useState(props.benificiaire.comment_medecin);
  const [consultationList, setConsultationList] = useState([]);
  const [fileList, setFileList] = useState(props.benificiaire.attachements);

  const [firstName, setFirstName] = useState(props.benificiaire.first_name);
  const [lastName, setLastName] = useState(props.benificiaire.last_name);
  const [birthDate, setBirthDate] = useState(props.benificiaire.birth_date || (new Date()).toISOString().substr(0, 10));

  const [startDateMyDoctor, setStartDateMyDoctor] = useState(props.benificiaire.start_date_my_doctor || (new Date()).toISOString().substr(0, 10));
  const [endDateMyDoctor, setEndDateMyDoctor] = useState(props.benificiaire.end_date_my_doctor);
  const [familyLink, setFamilyLink] = useState(props.benificiaire.family_link);
  const [sex, setSex] = useState(props.benificiaire.sex);
  const [adresse, setAdresse] = useState(props.benificiaire.adresse);
  const [commune, setCommune] = useState(props.benificiaire.commune);
  const [departement, setDepartement] = useState(props.benificiaire.departement);
  const [region, setRegion] = useState(props.benificiaire.region);
  const [country, setCountry] = useState(props.benificiaire.country);
  const [cellPhone, setCellPhone] = useState(props.benificiaire.cell_phone);
  const [phoneNumber, setPhoneNumber] = useState(props.benificiaire.phone_number);
  const [fax, setFax] = useState(props.benificiaire.fax);
  const [email, setEmail] = useState(props.benificiaire.email_address);
  const [SSN, setSSN] = useState(props.benificiaire.ssn);
  const [CIN, setCIN] = useState(props.benificiaire.CIN);
  const [groupeSanguin, setGroupeSanguin] = useState(props.benificiaire.groupe_sanguin);
  const [urgentContact, setUrgentContact] = useState(props.benificiaire.urgent_contact || [{}, {}, {}]);

  const [medecinList, setMedecinList] = useState([]);
  const [medecin, setMedecin] = useState(props.benificiaire.medecin);


  useEffect(() => {
    const url = `${API_URL}/consultation/benif/${props.benif}?patient=${localStorage.patient}`;
    axiosInstance.get(url).then(response => response.data)
      .then((lst) => {
        setConsultationList(lst)
      });
  }, []);

  useEffect(() => {
    const url = `${API_URL}/medecins`;
    axiosInstance.get(url).then(response => response.data)
      .then((doctorList) => {
        setMedecinList(doctorList)
      });
  }, []);
  const classes = useStyles();
  function closeModal() {
    props.onChange(true);
  }

  function handleSubmit(event) {
    setLoading(true);
    if (props.benificiaire._id) {
      const dataToSend = {
        _id: props.benificiaire._id,
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        birth_date: birthDate,
        family_link: familyLink,
        sex,
        adresse,
        commune,
        departement,
        region,
        country,
        fax,
        CIN,
        cell_phone: cellPhone,
        email_address: email,
        ssn: SSN,
        groupe_sanguin: groupeSanguin,
        medecin,
        urgent_contact: urgentContact,
        start_date_my_doctor: startDateMyDoctor,
        end_date_my_doctor: endDateMyDoctor
      }
      const url = `${API_URL}/benificiares/${props.benificiaire._id}`;
      axiosInstance.post(url, dataToSend).then(response => response.data)
        .then((result) => { closeModal() }
        );
    }
  }
  function sendFiles(ordonnanceId) {
    if (fileList) {
      if (fileList && fileList.length > 0 && fileList[0]._id) { closeModal(); }
      else if (fileList.length == 0) { closeModal(); }
      else {
        const formData = new FormData();
        for (var x = 0; x < fileList.length; x++) {
          formData.append("file", fileList[x], fileList[x].name);
        }
        const url = `${API_URL}/ordonnances/${ordonnanceId}/benif/${props.benif}/upload/${fileList[0].name}?patient=${localStorage.patient}`
        axiosInstance.post(url, formData).then(response => response.data)
          .then((result) => {
            closeModal();
          }
          );
      }
    } else {
      closeModal()
    }
  }

  function handleUrgentContact(i, field, value) {
    if (urgentContact.length == 0) {

      setUrgentContact([{ last_name: '' }, { last_name: '' }, { last_name: '' }])
    }
    urgentContact[i][field] = value
    setUrgentContact(urgentContact)
  };
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={closeModal.bind(this)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal__header">
        <div className="modal__header-title">
          {
            !props.benificiaire._id ? 'Nouvelle benificiaire' : `Mise à jour du benificiaire`
          }
        </div>
        <div className="modal__header-close">
        </div>
      </div>

      <div className="modal__body">

        <div className="modal__body-description">
          Les champs obligatoires sont suivi par une *
        </div>


        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <div className={classes.form} >
              <div className={'content-modal'}>Medecin traitant

                <div className={classes.paper}>
                  <div className={classes.form} >
                    {
                      props.benificiaire.medecin ?
                        <div className={'select__container_fix'}>
                          {
                            props.benificiaire.medecin.speciality
                            + ' ' +
                            props.benificiaire.medecin.last_name
                            + ' ' +
                            props.benificiaire.medecin.first_name}</div> : null
                    }
                    <Autocomplete
                      className={'Autocomplete'}
                      id="combo-box-demo"
                      options={medecinList}
                      getOptionLabel={(option) => option.last_name + ' ' + option.first_name}
                      onChange={(event, newValue) => {
                        if (newValue && newValue._id)
                          setMedecin(newValue._id);
                        else
                          setMedecin('');
                      }}
                      renderInput={(params) => <TextField {...params} label="Selectionner un medecin" variant="outlined" />}
                    />
                    <CssBaseline />

                    <TextField id="date" label="Date de debut" type="date" value={startDateMyDoctor}
                      onChange={event => setStartDateMyDoctor(event.target.value)}
                      InputLabelProps={{ shrink: true }} />
                    <TextField id="date" label="Date de fin" type="date" className={"margin-left"}
                      value={endDateMyDoctor}
                      onChange={event => setEndDateMyDoctor(event.target.value)}
                      InputLabelProps={{ shrink: true }} />

                    <TextField margin="normal" fullWidth label="Nom*" name="lastName" value={lastName} onChange={event => setLastName(event.target.value)} />
                    <TextField margin="normal" fullWidth label="Prenom*" name="firstName" value={firstName} onChange={event => setFirstName(event.target.value)} />
                    <TextField id="date" label="Date de naissance*" type="date" value={birthDate}
                      onChange={event => setBirthDate(event.target.value)}
                      InputLabelProps={{ shrink: true }} />
                    <div className="flex">
                      <div className="label-input">
                        Lien de parenté
                      </div>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={familyLink}
                        onChange={(event) => setFamilyLink(event.target.value)}
                      >
                        {FamilyLinkList.map((name, index) => (
                          <MenuItem key={name} value={index}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="flex">
                      <div className="label-input">
                        Sexe
                      </div>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sex}
                        onChange={(event) => setSex(event.target.value)}
                      >
                        {SexList.map((name, index) => (
                          <MenuItem key={name} value={index}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>

                    <TextField margin="normal" fullWidth label="Adresse" name="firstName" value={adresse} onChange={event => setAdresse(event.target.value)} />
                    <TextField margin="normal" fullWidth label="Ville" name="firstName" value={commune} onChange={event => setCommune(event.target.value)} />
                    <div className="flex">
                      <div className="label-input">
                        Province
                      </div>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={departement}
                        onChange={(event) => setDepartement(event.target.value)}
                      >
                        {ProvinceList.map((name, index) => (
                          <MenuItem key={name} value={index}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="flex">
                      <div className="label-input">
                        Région
                      </div>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={region}
                        onChange={(event) => setRegion(event.target.value)}
                      >
                        {RegionList.map((name, index) => (
                          <MenuItem key={name} value={index}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <TextField margin="normal" fullWidth label="Pays*" name="firstName" value={country} onChange={event => setCountry(event.target.value)} />
                    <TextField margin="normal" fullWidth label="Tel portable*" name="firstName" value={cellPhone} onChange={event => setCellPhone(event.target.value)} />
                    <TextField margin="normal" fullWidth label="Tel fixe" name="firstName" value={phoneNumber} onChange={event => setPhoneNumber(event.target.value)} />
                    <TextField margin="normal" fullWidth label="Email*" name="firstName" value={email} onChange={event => setEmail(event.target.value)} />
                    <TextField margin="normal" fullWidth label="Numéro de sécu Soc" name="firstName" value={SSN} onChange={event => setSSN(event.target.value)} />
                    <TextField margin="normal" fullWidth label="Numéro CIN" name="firstName" value={CIN} onChange={event => setCIN(event.target.value)} />
                    <TextField margin="normal" fullWidth label="groupe sanguin" name="groupe sanguin" value={groupeSanguin} onChange={event => setGroupeSanguin(event.target.value)} />
                    Contacts
                    {
                      [0, 1, 2].map((i, index) => {
                        return (
                          <div className="urgent-contact " key={i}>
                            <TextField margin="normal" fullWidth label="nom" name="firstName" value={urgentContact[i] ? urgentContact[i].last_name : ''} onChange={event => handleUrgentContact(i, 'last_name', event.target.value)} />
                            <TextField margin="normal" fullWidth label="prenom" name="lastName" value={urgentContact[i] ? urgentContact[i].first_name : ''} onChange={event => handleUrgentContact(i, 'first_name', event.target.value)} />
                            <TextField margin="normal" fullWidth label="lien" name="firstName" value={urgentContact[i] ? urgentContact[i].link : ''} onChange={event => handleUrgentContact(i, 'link', event.target.value)} />
                            <TextField margin="normal" fullWidth label="Tél" name="firstName" value={urgentContact[i] ? urgentContact[i].mobile : ''} onChange={event => handleUrgentContact(i, 'mobile', event.target.value)} />
                            <TextField margin="normal" fullWidth label="mail" name="firstName" value={urgentContact[i] ? urgentContact[i].mail : ''} onChange={event => handleUrgentContact(i, 'mail', event.target.value)} />
                          </div>
                        )
                      })
                    }

                  </div>
                </div>
              </div>

            </div>
          </div>
        </Container>

      </div>

      <div className={'footer-modal'}>
        <Button
          variant="contained"
          color="default"
          className={classes.submit}
          onClick={closeModal}
        > Annuler </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
        > Mise à jour</Button>
      </div>
    </Modal>
  );
}