import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { FaAngleDown, FaBackward, FaCalendarPlus, FaPoll, FaQrcode } from 'react-icons/fa';
import { FaTrash } from 'react-icons/fa';
import {
  BrowserRouter as Router,
  useParams
} from "react-router-dom";

import Modal from 'react-modal';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import axiosInstance from '../../../services/httpInterceptor'
import { useHistory } from "react-router-dom";
import { FamilyLinkList, ProvinceList, RegionList, SexList } from "../../../assets/references/referenceList";
import UrgentContact from './urgentContact'
import "./myHealth.scss";
import HeaderComponent from '../header/headerComponent'
import WarningMessage from '../../../shared/component/WarningMessage'
import DisplayFile from '../commun/displayFile'
import MaladiPerso from "./maladiPerso";
import MaladiOther from "./maladiOther";
import DocAdministratif from './DocAdministratif'
import MesureModal from "./MesureModal";
import { frenchDate } from '../../../shared/tools/formatDate'

import MesGraph from '../analyse/MesGraph';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import QRCode from "react-qr-code";


import { Logo1 } from '../../../assets/images/mesures/tensionsys.js';
import { Logo2 } from '../../../assets/images/mesures/tensionsdias.js';
import { Logo3 } from '../../../assets/images/mesures/fréquencecardiaque.js';
import { Logo4 } from '../../../assets/images/mesures/oxygen.js';
import { Logo5 } from '../../../assets/images/mesures/glycemie.js';
import { Logo6 } from '../../../assets/images/mesures/température.js';
import { Logo7 } from '../../../assets/images/mesures/taille.js';
import { Logo8 } from '../../../assets/images/mesures/poid.js';
import { Logo9 } from '../../../assets/images/mesures/tourdetaille.js';
import Logo10 from '../../../assets/images/logo1.png';
const logos = [Logo1, Logo2, Logo3, Logo4, Logo5, Logo6, Logo7, Logo8, Logo10, Logo9]
const API_URL = process.env.REACT_APP_URL;
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '300px',
    width: '500px',
    maxWidth: '80%',
    maxHeight: '80%',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  table: {

    background: '#dff9f3',
    margin: '5px 0'
  }
}));
const mapMesureType = ["", "Tension Sys", "Tension Dias", "Fré cardiaque", "Oxygene", "Glycémie", "Témpérature", "Taille", "Poids", "Masse corp", "Toure de Taille"]

const mesureList = ["Tension Sys (mmHg)", "Tension Dias (mmHg)", "Fré cardiaque (bpm)", "Oxygene (%)", "Glycémie (mg/dl)", "Témpérature (C)", "Taille (cm)", "Poids (kg)", "Masse corp (kg/m2)", "Toure de Taille (cm)"]


export default function myHealth() {
  const [mesureType, setMesureType] = useState(0);
  const [isMesureOpen, setIsMesureOpen] = useState(false);
  const [isGraphOpen, setIsGraphOpen] = useState(false);
  const [codeGraph, setCodeGraph] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [maladiPeroTab, setMaladiPeroTab] = useState(0);
  const [ImageTypeTab, setImageTypeTab] = useState(0);
  const [isFileOpen, setIsFileOpen] = useState(false);
  const [deletItem, setDeletItem] = useState({});
  const [fileJson, setFileJson] = useState('');
  let { id } = useParams();
  const history = useHistory()
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [filter, setFilter] = React.useState('1');
  const [category, setCategory] = React.useState('1');

  const [isOpenWarningPerso, setIsOpenWarningPerso] = useState(false);
  const [isOpenWarningDoc, setIsOpenWarningDoc] = useState(false);
  const [isOpenWarningOther, setIsOpenWarningOther] = useState(false);
  const [ordonnance, setOrdonnance] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [maladiPersoList, setMaladiPersoList] = useState([]);
  const [maladiOtherList, setMaladiOtherList] = useState([]);
  const [docAdministratifList, setDocAdministratifList] = useState([]);
  const [radioList, setRadioList] = useState([]);
  const [analyseList, setAnalyseList] = useState([]);
  const [mesuresList, setmMsuresList] = useState([]);
  const [maladiPerso, setMaladiPerso] = useState({});
  const [mesureData, setMesureData] = useState({});
  const [maladiOther, setMaladiOther] = useState({});
  const [docAdministratif, setDocAdministratif] = useState({});
  const [isMaladiPersoOpen, setIsMaladiPersoOpen] = useState(false);
  const [isMaladiOtherOpen, setIsMaladiOtherOpen] = useState(false);
  const [isDocAdministratifOpen, setIsDocAdministratifOpen] = useState(false);
  const [benif, setBenif] = useState({});
  const [isQRCodeModalOpen, setisQRCodeModalOpen] = useState(false);

  useEffect(() => {
    const url = `${API_URL}/benificiares/${id}?patient=${localStorage.patient}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        const benif = result[0];
        setBenif(benif)
      });
  }, []);
  useEffect(() => {
    const url = `${API_URL}/maladi-perso/benif/${id}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        setMaladiPersoList(result)
      });
    getRadioList()
    getAnalyseList()
  }, []);
  useEffect(() => {
    const url = `${API_URL}/maladi-other/benif/${id}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        setMaladiOtherList(result)
      });
  }, []);
  useEffect(() => {
    const url = `${API_URL}/mesures/benif/${id}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        setmMsuresList(result.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        }))
      });
  }, []);

  useEffect(() => {
    const url = `${API_URL}/doc-administratif/benif/${id}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        setDocAdministratifList(result.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        }))
      });
  }, []);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangeFilter = (panel) => (event, isExpanded) => {
    setFilter(panel);
  };

  function getRadioList() {
    const url = `${API_URL}/radios/benif/${id}?patient=${localStorage.patient}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        setRadioList(result.filter(item => item.radio_status == "Done"));
      }
      );
  }
  function getAnalyseList() {
    const url = `${API_URL}/analyses/benif/${id}?patient=${localStorage.patient}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        const graphData1 = []
        const graphData2 = []
        result.map(consultation => {
          if (consultation.results)
            consultation.results.map(categ => {
              categ.subCategories.map(subCateg => {
                subCateg.results.map(resSubCateg => {
                  graphData1.push({
                    category: categ.category,
                    subCategoryName: subCateg.subCategoryName,
                    date: consultation.date_rdv || consultation.date_prevu,
                    value: resSubCateg.value,
                    code: resSubCateg.code,
                    label: resSubCateg.label,
                    unit: resSubCateg.unit
                  })
                })
              })
            })
        })
        graphData1.forEach(element => {
          const index = graphData2.findIndex(i => i.code == element.code);
          if (index >= 0) {
            graphData2[index].results.push({ date: element.date, value: element.value })
          } else {
            const elementPush = {
              code: element.code,
              category: element.category,
              subCategoryName: element.subCategoryName,
              label: element.label,
              unit: element.unit,
              results: [{ date: element.date, value: element.value }]
            }
            graphData2.push(elementPush)
          }

        });
        setAnalyseList(graphData2);
      }
      );
  }
  function goBack() {
    navigateTo('/patient/user/' + id)
  }
  function navigateTo(url) {
    history.push(url);
  }

  function refreshList() {
    const url = `${API_URL}/benificiares/${id}?patient=${localStorage.patient}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        const benif = result[0];
        setBenif(benif)
      }
      );
    const url2 = `${API_URL}/maladi-perso/benif/${id}`;
    axiosInstance.get(url2).then(response => response.data)
      .then((result) => {
        setMaladiPersoList(result)
      });
    const url3 = `${API_URL}/maladi-other/benif/${id}`;
    axiosInstance.get(url3).then(response => response.data)
      .then((result) => {
        setMaladiOtherList(result)
      });

    const url4 = `${API_URL}/mesures/benif/${id}`;
    axiosInstance.get(url4).then(response => response.data)
      .then((result) => {
        setmMsuresList(result.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        }))
      });

    const url5 = `${API_URL}/doc-administratif/benif/${id}`;
    axiosInstance.get(url5).then(response => response.data)
      .then((result) => {
        setDocAdministratifList(result.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        }))
      });
  }
  function onChange(value) {
    setIsOpen(false)
    setIsMaladiOtherOpen(false)
    setIsMaladiPersoOpen(false)
    setIsMesureOpen(false)
    setIsDocAdministratifOpen(false)
    refreshList();
  }

  function onConfirmMaladiOther(value) {
    onCloseWarning();
    const url = `${API_URL}/maladi-other/${deletItem._id}?patient=${localStorage.patient}`;
    axiosInstance.delete(url).then(response => response.data)
      .then((result) => {
        refreshList()
      }
      );
    refreshList()
  }
  function onConfirmMaladiPerso(value) {
    onCloseWarning();
    const url = `${API_URL}/maladi-perso/${deletItem._id}?patient=${localStorage.patient}`;
    axiosInstance.delete(url).then(response => response.data)
      .then((result) => {
        refreshList()
      }
      );
    refreshList()
  }

  function onConfirmDocAdministratif(value) {
    onCloseWarning();
    const url = `${API_URL}/doc-administratif/${deletItem._id}?patient=${localStorage.patient}`;
    axiosInstance.delete(url).then(response => response.data)
      .then((result) => {
        refreshList()
      }
      );
    refreshList()
  }
  function onCloseWarning() {
    setIsOpenWarningPerso(false)
    setIsOpenWarningOther(false)
    setIsOpenWarningDoc(false)
  }

  function editBenif(benif) {
    setOrdonnance(ordonnance)
    setIsOpen(true)
  }
  function editMaladiPerso(maladiPerso, value) {
    setCategory(value)
    setMaladiPerso(maladiPerso)
    setIsMaladiPersoOpen(true)
  }
  function editMaladiOther(maladiPerso) {
    setMaladiOther(maladiPerso)
    setIsMaladiOtherOpen(true)
  }

  function deleteDocAdministratif(item) {
    setDeletItem(item)
    setIsOpenWarningDoc(true)
  }
  function editDocAdministratif(maladiPerso) {
    setDocAdministratif(maladiPerso)
    setIsDocAdministratifOpen(true)
  }

  function downloadFile(file) {
    setFileJson(file)
    setIsFileOpen(true)
  }
  function onChangeFile(value) {
    setIsFileOpen(false)
  }
  function addMaladiPerso(value) {
    setCategory(value)
    setMaladiPerso({})
    setIsMaladiPersoOpen(true)
  }
  function addMesure(index) {
    setMesureType(index)
    setMesureData({})
    setIsMesureOpen(true)
  }
  function addMaladiOther(value) {
    setMaladiOther({})
    setIsMaladiOtherOpen(true)
  }
  function addDocAdministratif(value) {
    setDocAdministratif({})
    setIsDocAdministratifOpen(true)
  }
  function deleteMaladiOther(item) {
    setDeletItem(item)
    setIsOpenWarningOther(true)
  }
  function deleteMaladiPerso(item) {
    setDeletItem(item)
    setIsOpenWarningPerso(true)
  }

  const handleChangeTab = (newValue) => {
    setMaladiPeroTab(newValue);
  };
  const handleChangeImageTypeTab = (newValue) => {
    setImageTypeTab(newValue);
  };

  function onChangeGraph(value) {
    setIsGraphOpen(false)
  }
  function openGraph(e, row) {
    const graphData = row.results;
    setCodeGraph(row)
    graphData.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });
    setGraphData(graphData)
    setIsGraphOpen(true)
  }
  function getRecentMesureValue(type) {
    const mesuresListFilter = mesuresList.filter(item => item.type == type)
    if (mesuresListFilter.length > 0)
      return mesuresListFilter[0].value
    return '-';

  }
  function displayQrCode() {
    setisQRCodeModalOpen(true);
  }
  function renderMesuresList(type) {
    const mesuresListFilter = mesuresList.filter(item => item.type == type)
    if (mesuresListFilter.length > 0)
      return (
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="left">Valeur</TableCell>
              <TableCell >Commentaire</TableCell>
              <TableCell ></TableCell>
            </TableRow>
          </TableHead>


          {
            mesuresList.filter(item => item.type == type).map((mesure, index) => {
              return (
                <TableBody>
                  <TableRow key={mesure._id}>
                    <TableCell align="">{frenchDate(mesure.date)}</TableCell>
                    <TableCell >{mesure.value}</TableCell>
                    <TableCell >{mesure.comment}</TableCell>
                  </TableRow>
                </TableBody>
              )
            })}
        </Table>
      )
  }
  function renderMaladiePerso() {
    return (
      <div className={classes.root}>
        <div className="container-tabs-top">
          <div className={maladiPeroTab == '0' ? "container-tab-top-actif" : "container-tab-top"}
            onClick={(e) => handleChangeTab(0)}>Mesures</div>
          <div className={maladiPeroTab == '1' ? "container-tab-top-actif" : "container-tab-top"}
            onClick={(e) => handleChangeTab(1)}>Maladies</div>
          <div className={maladiPeroTab == '2' ? "container-tab-top-actif" : "container-tab-top"}
            onClick={(e) => handleChangeTab(2)}>Allergies</div>

          <div className={maladiPeroTab == '3' ? "container-tab-top-actif" : "container-tab-top"}
            onClick={(e) => handleChangeTab(3)}>Vaccins</div>
        </div>

        <div className="container-tabs-top">
          <div className={maladiPeroTab == '4' ? "container-tab-top-actif" : "container-tab-top"}
            onClick={(e) => handleChangeTab(4)}>Biologies</div>
          <div className={maladiPeroTab == '5' ? "container-tab-top-actif" : "container-tab-top"}
            onClick={(e) => handleChangeTab(5)}>Imageries</div>

        </div>


        {maladiPeroTab === 5 &&
          <div className="min-height-40">
            {/* <Tabs
              value={ImageTypeTab}
              onChange={handleChangeImageTypeTab}
              orientation="vertical"
              variant="scrollable"
              aria-label="Vertical tabs example"
              className={classes.tabs}
            >
              <Tab label="Radiographie" />
              <Tab label="Scanner" />
              <Tab label="Scintigraphie" />
              <Tab label="Tomographie" />
              <Tab label="Échographie" />
              <Tab label="Électro-encéphalographie" />
              <Tab label="Magnétoencéphalographie" />
              <Tab label="Imagerie" />
              <Tab label="résonance magnétique" />
              <Tab label="IRM" />
            </Tabs> */}


            <div className="container-tabs-top">
              <div className={ImageTypeTab == '0' ? "container-tab-top-actif small-text" : "container-tab-top small-text"}
                onClick={(e) => handleChangeImageTypeTab(0)}>Radiographie</div>
              <div className={ImageTypeTab == '1' ? "container-tab-top-actif small-text" : "container-tab-top small-text"}
                onClick={(e) => handleChangeImageTypeTab(1)}>Scanner</div>
              <div className={ImageTypeTab == '2' ? "container-tab-top-actif small-text" : "container-tab-top small-text"}
                onClick={(e) => handleChangeImageTypeTab(2)}>Scintigraphie</div>
              <div className={ImageTypeTab == '3' ? "container-tab-top-actif small-text" : "container-tab-top small-text"}
                onClick={(e) => handleChangeImageTypeTab(3)}>Tomographie</div>
            </div>


            <div className="container-tabs-top">
              <div className={ImageTypeTab == '4' ? "container-tab-top-actif small-text" : "container-tab-top small-text"}
                onClick={(e) => handleChangeImageTypeTab(4)}>Échographie</div>
              <div className={ImageTypeTab == '9' ? "container-tab-top-actif small-text" : "container-tab-top small-text"}
                onClick={(e) => handleChangeImageTypeTab(9)}>IRM</div>
              <div className={ImageTypeTab == '7' ? "container-tab-top-actif small-text" : "container-tab-top small-text"}
                onClick={(e) => handleChangeImageTypeTab(7)}>Imagerie</div>
            </div>
            <div className="container-tabs-top">
              <div className={ImageTypeTab == '8' ? "container-tab-top-actif small-text" : "container-tab-top small-text"}
                onClick={(e) => handleChangeImageTypeTab(8)}>résonance magnétique</div>

              <div className={ImageTypeTab == '5' ? "container-tab-top-actif small-text" : "container-tab-top small-text"}
                onClick={(e) => handleChangeImageTypeTab(5)}>Électro encéphalographie</div>
              <div className={ImageTypeTab == '6' ? "container-tab-top-actif small-text" : "container-tab-top small-text"}
                onClick={(e) => handleChangeImageTypeTab(6)}>Magnétoencé phalographie</div>
            </div>

            {radioList.filter(element => (element.image_type == ImageTypeTab + 1)).map((radio, index) => {
              return (
                <Accordion expanded={expanded === radio._id} onChange={handleChange(radio._id)}>
                  <AccordionSummary
                    expandIcon={<FaAngleDown />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className='accordion__title'>{frenchDate(radio.date_prevu)}</Typography>
                    <Typography className='accordion__subtitle'>{radio.laboratory}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='all_width'>

                      <Typography className="padding2">
                        {radio.note}
                      </Typography>
                      <div className="lines">
                        <div className="lines__line"> <div className="lines__title">Centre Imagerie</div><div className="lines__desc">{radio.laboratory}</div></div>
                        {
                          radio.ordonnance && radio.ordonnance.consultation &&
                          <div className="lines__line"> <div className="lines__title">Docteur Prescripteur</div><div className="lines__desc">{radio.ordonnance.consultation.medecin.last_name} {radio.ordonnance.consultation.medecin.first_name}</div></div>
                        }
                        <div className="lines__line"> <div className="lines__title">Ordonnance</div><div className="lines__desc">{radio.ordonnance.ordonnance_name}</div></div>
                        <div className="lines__line"> <div className="lines__title">Date réalisation</div><div className="lines__desc">{frenchDate(radio.date_rdv)} {radio.time_rdv}</div></div>
                        <div className="result__title"> Resultat </div>
                        {
                          radio.attachements.map((exam, index) => {
                            return (<div className="download-file result__exam-name-comment" onClick={(e) => downloadFile(exam)}> Télécharger l'image </div>)
                          })
                        }
                        <div className="lines__line"> <textarea className="lines__desc" value={radio.result_conclusion}></textarea></div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>

              )
            })}
          </div>

        }
        {
          maladiPeroTab === 4 &&
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Analyse</TableCell>
                <TableCell align="left">Category</TableCell>
                <TableCell >sub Category</TableCell>
                <TableCell ></TableCell>
              </TableRow>
            </TableHead>


            {
              analyseList.map((analyse, index) => {
                return (
                  <TableBody>
                    <TableRow key={analyse.code}>
                      <TableCell align="">{analyse.label}</TableCell>
                      <TableCell >{analyse.category}</TableCell>
                      <TableCell >{analyse.subCategoryName}</TableCell>
                      <TableCell>
                        <FaPoll className="graph-button" onClick={(e) => openGraph(e, analyse)} ></FaPoll>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
              })}
          </Table>
        }
        {
          maladiPeroTab === 1 &&
          <div className='all_width'>
            <div className="btn-action" onClick={(e) => addMaladiPerso('MALADIE')} ><FaCalendarPlus>  </FaCalendarPlus> Ajouter une maladie personnelle</div>
            {
              maladiPersoList.filter(item => item.category === 'MALADIE').map((maladiPersoItem, index) => {
                return (
                  <Accordion expanded={expanded === maladiPersoItem._id} onChange={handleChange(maladiPersoItem._id)}>
                    <AccordionSummary
                      expandIcon={<FaAngleDown />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className='accordion__title'>{maladiPersoItem.name ? maladiPersoItem.name : ''}</Typography>
                      <Typography className='accordion__subtitle'>{frenchDate(maladiPersoItem.date_start) + ' - ' + frenchDate(maladiPersoItem.date_end) + ' ' + (maladiPersoItem.stade ? maladiPersoItem.stade : '')}</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <div className='all_width'>

                        <div className="lines__footer">
                          <div className="lines__footer-action" onClick={(e) => editMaladiPerso(maladiPersoItem, 'MALADIE')}> <FaCalendarPlus>  </FaCalendarPlus>Modifier</div>
                          <div className="lines__footer-action" onClick={(e) => deleteMaladiPerso(maladiPersoItem)}> <FaTrash>  </FaTrash>Supprimer</div>
                        </div>
                        <div className="lines">
                          <div className="lines__line"> <div className="lines__title">Nom</div><div className="lines__desc">{maladiPersoItem.name}</div></div>
                          <div className="lines__line"> <div className="lines__title">Début</div><div className="lines__desc">{frenchDate(maladiPersoItem.date_start)}</div></div>
                          <div className="lines__line"> <div className="lines__title">Fin</div><div className="lines__desc">{frenchDate(maladiPersoItem.date_end)}</div></div>
                          <div className="lines__line"> <div className="lines__title">Stade</div><div className="lines__desc">{maladiPersoItem.stade}</div></div>
                          <div className="lines__line"> <div className="lines__title">Date Remission</div><div className="lines__desc">{frenchDate(maladiPersoItem.date_remission)}</div></div>
                          <div className="lines__line"> <div className="lines__title">Consequences</div><div className="lines__desc">{maladiPersoItem.consequences}</div></div>
                          <div className="lines__line"> <div className="lines__title">Traitement</div><div className="lines__desc">{maladiPersoItem.traitement}</div></div>
                          <div className="lines__line"> <div className="lines__title">Statut traitement</div><div className="lines__desc">{maladiPersoItem.traitement_status}</div></div>
                          <div className="lines__line"> <div className="lines__title">Début</div><div className="lines__desc">{frenchDate(maladiPersoItem.traitement_date_start)}</div></div>

                          <div className="lines__line"> <div className="lines__title">Fin</div><div className="lines__desc">{frenchDate(maladiPersoItem.traitement_date_end)}</div></div>
                          <div className="lines__line"> <div className="lines__title">Medicaments traitement</div><div className="lines__desc">{maladiPersoItem.traitement_drugs}</div></div>

                          <div className="lines__line"> <div className="lines__title">Commentaire</div><div className="lines__desc">{maladiPersoItem.comments}</div></div>
                          <div className="lines__line"> <div className="lines__title">Pièces jointes</div>
                            {
                              maladiPersoItem.attachements.map((exam, index) => {
                                return (<div className="download-file lines__desc" onClick={(e) => downloadFile(exam)}> Télécharger la pièce jointe </div>)
                              })
                            }
                          </div>


                        </div>

                      </div>

                    </AccordionDetails>
                  </Accordion>
                )
              })
            }
          </div>
        }

        {
          maladiPeroTab === 2 &&
          <div className='all_width'>
            <div className="btn-action" onClick={(e) => addMaladiPerso('ALLERGIE')} ><FaCalendarPlus>  </FaCalendarPlus> Ajouter une Allergie</div>
            {
              maladiPersoList.filter(item => item.category === 'ALLERGIE').map((maladiPersoItem, index) => {
                return (
                  <Accordion expanded={expanded === maladiPersoItem._id} onChange={handleChange(maladiPersoItem._id)}>
                    <AccordionSummary
                      expandIcon={<FaAngleDown />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className='accordion__title'>{maladiPersoItem.category}</Typography>
                      <Typography className='accordion__subtitle'>{maladiPersoItem.name}</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <div className='all_width'>

                        <div className="lines__footer">
                          <div className="lines__footer-action" onClick={(e) => editMaladiPerso(maladiPersoItem, 'ALLERGIE')}> <FaCalendarPlus>  </FaCalendarPlus>Modifier</div>
                          <div className="lines__footer-action" onClick={(e) => deleteMaladiPerso(maladiPersoItem)}> <FaTrash>  </FaTrash>Supprimer</div>
                        </div>
                        <div className="lines">
                          <div className="lines__line"> <div className="lines__title">Nom </div><div className="lines__desc">{maladiPersoItem.name}</div></div>
                          <div className="lines__line"> <div className="lines__title">Début</div><div className="lines__desc">{frenchDate(maladiPersoItem.date_start)}</div></div>
                          <div className="lines__line"> <div className="lines__title">Date de fin </div><div className="lines__desc">{frenchDate(maladiPersoItem.date_end)}</div></div>
                          <div className="lines__line"> <div className="lines__title">Consequences </div><div className="lines__desc">{maladiPersoItem.consequences}</div></div>
                          <div className="lines__line"> <div className="lines__title">Medicaments traitement </div><div className="lines__desc">{maladiPersoItem.traitement_drugs}</div></div>
                          <div className="lines__line"> <div className="lines__title">Commentaire</div><div className="lines__desc">{maladiPersoItem.comments}</div></div>
                          <div className="lines__line"> <div className="lines__title">Pièces jointes </div>
                            {
                              maladiPersoItem.attachements.map((exam, index) => {
                                return (<div className="download-file lines__desc" onClick={(e) => downloadFile(exam)}> Télécharger la pièce jointe </div>)
                              })
                            }
                          </div>


                        </div>

                      </div>

                    </AccordionDetails>
                  </Accordion>
                )
              })
            }
          </div>
        }


        {
          maladiPeroTab === 3 &&
          <div className='all_width'>
            <div className="btn-action" onClick={(e) => addMaladiPerso('VACCIN')} ><FaCalendarPlus>  </FaCalendarPlus> Ajouter un vaccin</div>
            {
              maladiPersoList.filter(item => item.category === 'VACCIN').map((maladiPersoItem, index) => {
                return (
                  <Accordion expanded={expanded === maladiPersoItem._id} onChange={handleChange(maladiPersoItem._id)}>
                    <AccordionSummary
                      expandIcon={<FaAngleDown />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className='accordion__title'>Vaccin</Typography>
                      <Typography className='accordion__subtitle'>{maladiPersoItem.name}</Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                      <div className='all_width'>

                        <div className="lines__footer">
                          <div className="lines__footer-action" onClick={(e) => editMaladiPerso(maladiPersoItem, 'VACCIN')}> <FaCalendarPlus>  </FaCalendarPlus>Modifier</div>
                          <div className="lines__footer-action" onClick={(e) => deleteMaladiPerso(maladiPersoItem)}> <FaTrash>  </FaTrash>Supprimer</div>
                        </div>
                        <div className="lines">
                          <div className="lines__line"> <div className="lines__title">Nom </div><div className="lines__desc">{maladiPersoItem.name}</div></div>
                          <div className="lines__line"> <div className="lines__title">Dose 1 </div><div className="lines__desc">{maladiPersoItem.dose1}</div></div>
                          <div className="lines__line"> <div className="lines__title">Dose 2 </div><div className="lines__desc">{maladiPersoItem.dose2}</div></div>
                          <div className="lines__line"> <div className="lines__title">Dose 3 </div><div className="lines__desc">{maladiPersoItem.dose3}</div></div>
                          <div className="lines__line"> <div className="lines__title">Commentaire</div><div className="lines__desc">{maladiPersoItem.comments}</div></div>
                          <div className="lines__line"> <div className="lines__title">Pièces jointes </div>
                            {
                              maladiPersoItem.attachements.map((exam, index) => {
                                return (<div className="download-file lines__desc" onClick={(e) => downloadFile(exam)}> Télécharger la pièce jointe </div>)
                              })
                            }
                          </div>


                        </div>

                      </div>

                    </AccordionDetails>
                  </Accordion>
                )
              })
            }
          </div>
        }

        {
          maladiPeroTab === 0 &&
          <div className='all_width'>

            {mesureList.map((mesureLabel, index) => {
              return (
                <Accordion expanded={expanded === index} onChange={handleChange(index)}>
                  <AccordionSummary
                    expandIcon={<FaAngleDown />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className='accordion__subtitle all_width'>
                      <div className="container-widget-info" >
                        <div className="container-label-info">
                          <div className="span-label-info">{mesureLabel}</div>
                          <div className="span-label-info size-24">{getRecentMesureValue(index + 1)} </div>
                        </div>
                        <div className="container-widget-info-img">
                          <img src={logos[index]}
                            alt="un triangle aux trois côtés égaux"
                            className="container-img-info" />
                        </div>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='all_width'>
                      <div className="btn-action" onClick={(e) => addMesure(index + 1)} ><FaCalendarPlus>  </FaCalendarPlus> Ajouter une mesure :{mapMesureType[index + 1]}</div>
                      {renderMesuresList(index + 1)}
                    </div>
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </div>
        }
      </div >
    )
  }
  function openResume() {
    navigateTo('/patient/benificiaire/resume/' + id)
  }
  function renderMaladieFamille() {
    return (
      <div className={classes.root}>
        <div className='all_width'>
          <div className="btn-action" onClick={(e) => addMaladiOther()} ><FaCalendarPlus>  </FaCalendarPlus> Ajouter une maladie Familliale</div>
          {
            maladiOtherList.map((maladiPersoItem, index) => {
              return (
                <Accordion expanded={expanded === maladiPersoItem._id} onChange={handleChange(maladiPersoItem._id)}>
                  <AccordionSummary
                    expandIcon={<FaAngleDown />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className='accordion__title'>{maladiPersoItem.family_link}</Typography>
                    <Typography className='accordion__subtitle'>{maladiPersoItem.name + ' ' + maladiPersoItem.stade}</Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <div className='all_width'>

                      <div className="lines__footer">
                        <div className="lines__footer-action" onClick={(e) => editMaladiOther(maladiPersoItem)}> <FaCalendarPlus>  </FaCalendarPlus>Modifier</div>
                        <div className="lines__footer-action" onClick={(e) => deleteMaladiOther(maladiPersoItem)}> <FaTrash>  </FaTrash>Supprimer</div>
                      </div>
                      <div className="lines">
                        <div className="lines__line"> <div className="lines__title">Lien </div><div className="lines__desc">{maladiPersoItem.family_link}</div></div>
                        <div className="lines__line"> <div className="lines__title">Nom </div><div className="lines__desc">{maladiPersoItem.name}</div></div>
                        <div className="lines__line"> <div className="lines__title">Catégorie </div><div className="lines__desc">{maladiPersoItem.category}</div></div>
                        <div className="lines__line"> <div className="lines__title">Début</div><div className="lines__desc">{frenchDate(maladiPersoItem.date_start)}</div></div>
                        <div className="lines__line"> <div className="lines__title">Fin </div><div className="lines__desc">{frenchDate(maladiPersoItem.date_end)}</div></div>
                        <div className="lines__line"> <div className="lines__title">Stade </div><div className="lines__desc">{maladiPersoItem.stade}</div></div>
                        <div className="lines__line"> <div className="lines__title">Consequences </div><div className="lines__desc">{maladiPersoItem.consequences}</div></div>
                        <div className="lines__line"> <div className="lines__title">Traitement </div><div className="lines__desc">{maladiPersoItem.traitement}</div></div>
                        <div className="lines__line"> <div className="lines__title">Statut traitement</div><div className="lines__desc">{maladiPersoItem.traitement_status}</div></div>
                        <div className="lines__line"> <div className="lines__title">Début </div><div className="lines__desc">{frenchDate(maladiPersoItem.traitement_date_start)}</div></div>

                        <div className="lines__line"> <div className="lines__title">Fin </div><div className="lines__desc">{frenchDate(maladiPersoItem.traitement_date_end)}</div></div>

                        <div className="lines__line"> <div className="lines__title">Commentaire</div><div className="lines__desc">{maladiPersoItem.comments}</div></div>
                        <div className="lines__line"> <div className="lines__title">Pièces jointes </div>
                          {
                            maladiPersoItem.attachements.map((exam, index) => {
                              return (<div className="download-file lines__desc" onClick={(e) => downloadFile(exam)}> Télécharger la pièce jointe </div>)
                            })
                          }
                        </div>


                      </div>

                    </div>

                  </AccordionDetails>
                </Accordion>
              )
            })
          }


        </div>
      </div>
    )
  }
  function renderDocAdministratif() {
    return (
      <div className={classes.root}>
        <div className='all_width'>
          <div className="btn-action" onClick={(e) => addDocAdministratif()} ><FaCalendarPlus>  </FaCalendarPlus> Ajouter un document administratif</div>
          {
            docAdministratifList.map((document, index) => {
              return (
                <Accordion expanded={expanded === document._id} onChange={handleChange(document._id)}>
                  <AccordionSummary
                    expandIcon={<FaAngleDown />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className='accordion__title'>{frenchDate(document.date)}</Typography>
                    <Typography className='accordion__subtitle'>{document.name}</Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <div className='all_width'>

                      <div className="lines__footer">
                        <div className="lines__footer-action" onClick={(e) => editDocAdministratif(document)}> <FaCalendarPlus>  </FaCalendarPlus>Modifier</div>
                        <div className="lines__footer-action" onClick={(e) => deleteDocAdministratif(document)}> <FaTrash>  </FaTrash>Supprimer</div>
                      </div>
                      <div className="lines">
                        <div className="lines__line"> <div className="lines__title">Nom </div><div className="lines__desc">{document.name}</div></div>
                        <div className="lines__line"> <div className="lines__title">Date </div><div className="lines__desc">{frenchDate(document.date) + ' ' + (document.time ? document.time : '')}</div></div>

                        <div className="lines__line"> <div className="lines__title">date début </div><div className="lines__desc">{frenchDate(document.date_start_validity)}</div></div>
                        <div className="lines__line"> <div className="lines__title">Date fin </div><div className="lines__desc">{frenchDate(document.date_end_validity)}</div></div>

                        <div className="lines__line"> <div className="lines__title">Commentaire</div><div className="lines__desc">{document.comment}</div></div>
                        <div className="lines__line"> <div className="lines__title">Pièces jointes </div>
                          {
                            document.attachements.map((exam, index) => {
                              return (<div className="download-file lines__desc" onClick={(e) => downloadFile(exam)}> Télécharger la pièce jointe </div>)
                            })
                          }
                        </div>
                      </div>

                    </div>

                  </AccordionDetails>
                </Accordion>
              )
            })
          }
        </div>
      </div>
    )
  }
  function renderInfoPerso() {
    return (
      <div className={classes.root}>
        <Modal
          isOpen={isQRCodeModalOpen}
          onRequestClose={setisQRCodeModalOpen.bind(this, false)}
          style={customStyles}
          contentLabel="Example Modal"
        >

          <QRCode value={'{DDF093D} ' + id} />
        </Modal>
        <div className='all_width'>
          <div className="lines">
            <div className="lines__line"> <div className="lines__title">QR code</div><div className="lines__desc link" onClick={(e) => displayQrCode()}>Afficher <FaQrcode></FaQrcode></div></div>
            <div className="lines__line"> <div className="lines__title">ID patient</div><div className="lines__desc">{benif.uuid}</div></div>
            <div className="lines__line"> <div className="lines__title">Nom</div><div className="lines__desc">{benif.last_name ? (benif.last_name || '') + ' ' + (benif.first_name || '') : ''}</div></div>
            <div className="lines__line"> <div className="lines__title">Date de naissance</div><div className="lines__desc">{frenchDate(benif.birth_date)}</div></div>
            <div className="lines__line"> <div className="lines__title">Ville</div><div className="lines__desc">{benif.commune}</div></div>
            <div className="lines__line"> <div className="lines__title">Province</div><div className="lines__desc">{ProvinceList[benif.departement] || ''}</div></div>
            <div className="lines__line"> <div className="lines__title">Région</div><div className="lines__desc">{RegionList[benif.region] || ''}</div></div>
            <div className="lines__line"> <div className="lines__title">Pays</div><div className="lines__desc">{benif.country}</div></div>
            <div className="lines__line"> <div className="lines__title">Num de tél</div><div className="lines__desc">{benif.cell_phone}</div></div>
            <div className="lines__line"> <div className="lines__title">Sexe</div><div className="lines__desc">{SexList[benif.sex] || ''}</div></div>
            <div className="lines__line"> <div className="lines__title">Email</div><div className="lines__desc">{benif.email_address}</div></div>
            <div className="lines__line"> <div className="lines__title">CNSS</div><div className="lines__desc">{benif.ssn}</div></div>
            <div className="lines__line"> <div className="lines__title">CIN</div><div className="lines__desc">{benif.CIN}</div></div>
            <div className="lines__line"> <div className="lines__title">Groupe sanguin</div><div className="lines__desc">{benif.groupe_sanguin}</div></div>

            <div className="lines__line"> <div className="lines__title">Medecin traitant</div><div className="lines__desc">{benif.medecin ? benif.medecin.first_name + ' ' + benif.medecin.last_name : ''}</div></div>
            <div className="lines__line"> <div className="lines__title">Date de début</div><div className="lines__desc">{frenchDate(benif.start_date_my_doctor)}</div></div>
            <div className="lines__line"> <div className="lines__title">Date de fin</div><div className="lines__desc">{frenchDate(benif.end_date_my_doctor)}</div></div>
            <div className="lines__line"> <div className="lines__title">Contacts</div></div>

            <div>
              {benif.urgent_contact ?
                benif.urgent_contact.map((urgentContact, i) => {
                  return (
                    <div className="urgent-contact " key={i}>
                      <div className="lines__line"> <div className="lines__title">Contact {i + 1}</div><div className="lines__desc">{(urgentContact.last_name || "") + ' ' + (urgentContact.first_name || "")}</div></div>
                      <div className="lines__line"> <div className="lines__title">Lien</div><div className="lines__desc">{urgentContact.link}</div></div>
                      <div className="lines__line"> <div className="lines__title">Téléphone</div><div className="lines__desc">{urgentContact.mobile}</div></div>
                      <div className="lines__line"> <div className="lines__title">Email</div><div className="lines__desc">{urgentContact.mail}</div></div>
                    </div>
                  )
                }) : null
              }
            </div>
          </div>
          <div className="lines__footer">
            <div className="lines__footer-action" onClick={(e) => editBenif(benif)}> <FaCalendarPlus>  </FaCalendarPlus>Modifier</div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="container-wrapper">
      <HeaderComponent></HeaderComponent>
      <div className="container-return-action" onClick={(e) => goBack()}>
        <FaBackward>  </FaBackward> {'  '} &nbsp;&nbsp;Retour à l'accueil
      </div>
      {
        isGraphOpen ?
          <MesGraph
            onChangeGraph={onChangeGraph}
            isOpen={isGraphOpen}
            graphData={graphData}
            benif={benif}
            codeGraph={codeGraph}
          ></MesGraph>
          : null
      }

      {
        isOpen ?
          <UrgentContact
            onChange={onChange}
            isOpen={isOpen}
            benificiaire={benif}
            benif={id}
          ></UrgentContact>
          : null
      }
      {
        isMaladiPersoOpen ?
          <MaladiPerso
            onChange={onChange}
            category={category}
            isOpen={isMaladiPersoOpen}
            data={maladiPerso}
            benif={id}
          ></MaladiPerso>
          : null
      }

      {
        isMesureOpen ?
          <MesureModal
            onChange={onChange}
            type={mesureType}
            isOpen={isMesureOpen}
            data={mesureData}
            benif={id}
          ></MesureModal>
          : null
      }
      {
        isMaladiOtherOpen ?
          <MaladiOther
            onChange={onChange}
            isOpen={isMaladiOtherOpen}
            data={maladiOther}
            benif={id}
          ></MaladiOther>
          : null
      }

      {
        isDocAdministratifOpen ?
          <DocAdministratif
            onChange={onChange}
            isOpen={isDocAdministratifOpen}
            data={docAdministratif}
            benif={id}
          ></DocAdministratif>
          : null
      }
      {
        isFileOpen ?
          <DisplayFile
            onChangeFile={onChangeFile}
            isOpen={isFileOpen}
            fileJson={fileJson}
            benif={id}
          ></DisplayFile>
          : null
      }
      <WarningMessage
        onCloseWarning={onCloseWarning}
        isOpenWarning={isOpenWarningPerso}
        onConfirm={onConfirmMaladiPerso}>
      </WarningMessage>

      <WarningMessage
        onCloseWarning={onCloseWarning}
        isOpenWarning={isOpenWarningDoc}
        onConfirm={onConfirmDocAdministratif}>
      </WarningMessage>
      <WarningMessage
        onCloseWarning={onCloseWarning}
        isOpenWarning={isOpenWarningOther}
        onConfirm={onConfirmMaladiOther}>
      </WarningMessage>
      <div className="container-body">

        <div className="container-title">
          Mes infos
        </div>
        <div className="container-filters-top">
          <div className={filter === '1' ? "container-filter-top-actif" : "container-filter-top"}
            onClick={handleChangeFilter('1')}>Profil médicale</div>
          <div className={filter === '2' ? "container-filter-top-actif" : "container-filter-top"}
            onClick={handleChangeFilter('2')}>Ma santé</div>
          <div className={filter === '3' ? "container-filter-top-actif" : "container-filter-top"}
            onClick={handleChangeFilter('3')}>Mes proches</div>

          <div className={filter === '4' ? "container-filter-top-actif" : "container-filter-top"}
            onClick={handleChangeFilter('4')}>Doc Administratif</div>
        </div>
        {filter == 1 && renderInfoPerso()}
        {filter == 2 && renderMaladiePerso()}
        {filter == 3 && renderMaladieFamille()}
        {filter == 4 && renderDocAdministratif()}
      </div>
    </div>
  );
}