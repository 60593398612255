import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Modal from 'react-modal';
import { FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';

import Autocomplete from '@material-ui/lab/Autocomplete';
import axiosInstance from '../../../services/httpInterceptor'
import "./urgentContact.scss";
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '470px',
    width: '500px',
    maxWidth: '80%',
    maxHeight: '80%',
    padding: 0
  }
};
const API_URL = process.env.REACT_APP_URL;

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));



const mapMesureType = ["", "Tension Sys", "Tension Dias", "Fré cardiaque", "Oxygene", "Glycémie", "Témpérature", "Taille", "Poids", "Masse corp", "Toure de Taille"]

export default function MesureModal(props) {
  const [date, setDate] = useState(props.data.date || (new Date()).toISOString().substr(0, 10));
  const [type, setType] = useState(props.type);
  const [time, setTime] = useState(props.data.time);
  const [value, setValue] = useState(props.data.value);
  const [comment, setComment] = useState(props.data.comment);
  const [comment_pro, setCommentPro] = useState(props.data.comment_pro);

  const classes = useStyles();
  function closeModal() {
    props.onChange(true);
  }

  function handleSubmit(event) {
    if (props.benif) {
      const id = (props.data && props.data._id) ? props.data._id : null;
      const dataToSend = {
        benificiaire: props.benif,
        type: props.type,
        date,
        value,
        comment,
        comment_pro,
        time
      }
      if (id)
        dataToSend._id = id
      const url = `${API_URL}/mesures`;
      axiosInstance.post(url, dataToSend).then(response => response.data)
        .then((result) => {
          closeModal()
        }
        );
    }
  }
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={closeModal.bind(this)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal__header">
        <div className="modal__header-title">
          {
            !props.data._id ? 'nouvelle entrée' : `Mise à jour`
          }
        </div>
        <div className="modal__header-close">
        </div>
      </div>

      <div className="modal__body">

        <div className="modal__body-description">
          Les champs obligatoires sont suivi par une *
        </div>


        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <div className={classes.form} >
              <div className={'content-modal'}>
                <Container component="main" maxWidth="xs">
                  <div className={classes.paper}>
                    <div className={classes.form} >
                      <div className={'content-modal'}>
                        <TextField margin="normal" fullWidth label="Type" name="lastName" value={mapMesureType[type]} disabled />
                        <TextField margin="normal" fullWidth label="Valeur" name="firstName" value={value} onChange={event => setValue(event.target.value)} />
                        <TextField margin="normal" fullWidth label="Commentaire" name="firstName" value={comment} onChange={event => setComment(event.target.value)} />
                        <TextField margin="normal" fullWidth label="Commentaire Pro" name="firstName" value={comment_pro} onChange={event => setCommentPro(event.target.value)} />
                        <TextField id="date" label="Date" type="date" value={date}
                          onChange={event => setDate(event.target.value)}
                          InputLabelProps={{ shrink: true }} />
                        <TextField id="date" label="L'heure" type="time" value={time} className={'input-min-width'}
                          onChange={event => setTime(event.target.value)}
                          InputLabelProps={{ shrink: true }} />
                      </div>
                    </div>
                  </div>
                </Container>
              </div>

            </div>
          </div>
        </Container>

      </div>

      <div className={'footer-modal'}>
        <Button
          variant="contained"
          color="default"
          className={classes.submit}
          onClick={closeModal}
        > Annuler </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
        >
          {!props.data._id ? 'Ajouter' : `Mise à jour`}</Button>
      </div>
    </Modal>
  );
}