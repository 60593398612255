import React, { useState, useEffect } from "react";
import "./ProfileCard.scss";
import { FaTrash, FaSave } from 'react-icons/fa';

import { toast } from 'react-toastify';

export default function AttachementComponenet(props) {
  const [fileList, setFileList] = useState([]);

  function deleteFile(e, file, index) {
    setFileList([]);
  }
  function saveFile(e, file, index) {
    props.saveFile(file);
  }
  function uploadFile(event) {
    const file = event[0];
    if (file.size > 5000000) {
      toast.error('fichier trés large');
      return
    }
    const array = []
    for (let i = 0; i < event.length; i++) {
      array.push(event[i])
    }
    setFileList(array);
  }
  return (
    <div className="card-container">
      {
        (fileList && fileList.length > 0) ?
          (fileList).map((item, index) => {
            return (<div key={index}> {item.name}
              <FaTrash className="att-btn" onClick={(e) => deleteFile(e, item, index)}></FaTrash>
              <FaSave className="att-btn" onClick={(e) => saveFile(e, item, index)}>s</FaSave>
            </div>);
          })
          : null
      }
      {
        fileList.length == 0 ?
          <div className={'drag-container'}>
            <input
              type="file"
              onChange={(e) => uploadFile(e.target.files)} />

            <span>Parcourir mon ordinateur (Max 5M)</span>
          </div> : null
      }
    </div>
  );
}
