import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  useParams
} from "react-router-dom";
import "./benifDashboard.scss";
import HeaderComponent from '../header/headerComponent'
import Badge from '@material-ui/core/Badge';

import { FaEnvelope } from 'react-icons/fa';
import { useHistory } from "react-router-dom";
import axiosInstance from '../../../services/httpInterceptor'
import { Logo1 } from '../../../assets/images/consultations.js';
import { Logo2 } from '../../../assets/images/ordonnances.js';
import { Logo3 } from '../../../assets/images/traitement.js';
import { Logo4 } from '../../../assets/images/bioliogies.js';
import { Logo5 } from '../../../assets/images/imageries.js';
import { Logo6 } from '../../../assets/images/hospitalisations.js';
import { Logo7 } from '../../../assets/images/infos';
import { Logo8 } from '../../../assets/images/infopratiques.js';
import { Logo9 } from '../../../assets/images/scanqr.js';

const API_URL = process.env.REACT_APP_URL;

export default function BenifDashboard() {

  const history = useHistory()
  let { id } = useParams();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [notification, setNotification] = useState('');
  useEffect(() => {
    const url = `${API_URL}/benificiares/${id}?patient=${localStorage.patient}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        const benif = result[0];
        setFirstName(benif.first_name)
        setLastName(benif.last_name)
      });
    const urlNotif = `${API_URL}/benificiares/${id}/notifications?patient=${localStorage.patient}`;
    axiosInstance.get(urlNotif).then(response => response.data)
      .then((result) => {
        setNotification(result)
      }
      );
  }, []);


  function navigateTo(url) {
    history.push(url);
  }
  return (
    <div className="container-wrapper">
      <HeaderComponent></HeaderComponent>
      <div className="container-body">

        <div className="container-title">Bienvenue dans votre dossier médical  <span className="bold">{firstName} {lastName}</span></div>

        <div className="container-widget-line">

          <div className="container-widget" onClick={(e) => navigateTo(id + '/etat-sante')}>
            <div>
              <img src={Logo7}
                alt="un triangle aux trois côtés égaux"
                className="container-img" />
              <div className="container-label">Mes infos</div>
            </div>
          </div>
          <div className="container-widget" onClick={(e) => navigateTo(id + '/consultations')}>
            <div>
              {
                notification.countConsultation > 0 ?
                  <Badge badgeContent={notification.countConsultation} color="secondary">
                    <img src={Logo1}
                      alt="un triangle aux trois côtés égaux"
                      className="container-img" />
                  </Badge> :
                  <img src={Logo1}
                    alt="un triangle aux trois côtés égaux"
                    className="container-img" />
              }

              <div className="container-label">Consultations</div>
            </div>
          </div>
          <div className="container-widget" onClick={(e) => navigateTo(id + '/ordonnances')}>
            <div>
              {
                <Badge badgeContent={notification.countOrdonnance} color="secondary">
                  <Badge badgeContent={notification.countOrdonnanceInProgress}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                    className={`${notification.countOrdonnanceInProgress > 0 ? "badge-green" : "hidden"}`}>
                    <img src={Logo2}
                      alt="un triangle aux trois côtés égaux"
                      className="container-img" />
                  </Badge>
                </Badge>
              }
              <div className="container-label">Ordonnances</div>
            </div>
          </div>
        </div>

        <div className="container-widget-line">

          <div className="container-widget" onClick={(e) => navigateTo(id + '/traitements')}>
            <div>
              {
                <Badge badgeContent={notification.countTraitement} color="secondary">
                  <Badge badgeContent={notification.countTraitementInProgress}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                    className={`${notification.countTraitementInProgress > 0 ? "badge-green" : "hidden"}`}>
                    <img src={Logo3}
                      alt="un triangle aux trois côtés égaux"
                      className="container-img" />
                  </Badge>
                </Badge>
              }
              <div className="container-label">Traitements</div>
            </div>
          </div>
          <div className="container-widget" onClick={(e) => navigateTo(id + '/analyses')}>
            <div>

              {
                notification.countAnalyse > 0 ?
                  <Badge badgeContent={notification.countAnalyse} color="secondary">
                    <img src={Logo4}
                      alt="un triangle aux trois côtés égaux"
                      className="container-img" />
                  </Badge> :
                  <img src={Logo4}
                    alt="un triangle aux trois côtés égaux"
                    className="container-img" />
              }
              <div className="container-label">Biologies</div>
            </div>
          </div>
          <div className="container-widget" onClick={(e) => navigateTo(id + '/radios')}>
            <div>

              {
                notification.countRadio > 0 ?
                  <Badge badgeContent={notification.countRadio} color="secondary">
                    <img src={Logo5}
                      alt="un triangle aux trois côtés égaux"
                      className="container-img" />
                  </Badge> :
                  <img src={Logo5}
                    alt="un triangle aux trois côtés égaux"
                    className="container-img" />
              }
              <div className="container-label">Imageries</div>
            </div>

          </div>
        </div>

        <div className="container-widget-line">

          <div className="container-widget" onClick={(e) => navigateTo(id + '/hospitalisations')}>
            <div>

              {
                <Badge badgeContent={notification.countHospitalisation} color="secondary">
                  <Badge badgeContent={notification.countHospitalisationInProgress}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                    className={`${notification.countHospitalisationInProgress > 0 ? "badge-green" : "hidden"}`}>
                    <img src={Logo6}
                      alt="un triangle aux trois côtés égaux"
                      className="container-img" />
                  </Badge>
                </Badge>
              }
              <div className="container-label">Hospitalisations</div>
            </div>
          </div>
          <div className="container-widget" onClick={(e) => navigateTo(id + '/informations')}>
            <div>
              <img src={Logo8}
                alt="un triangle aux trois côtés égaux"
                className="container-img" />
              <div className="container-label">Info pratique</div>
            </div>
          </div>

          {/* <div className="container-widget" onClick={(e) => navigateTo(id + '/code-qr')}>
            <div>
              <img src={Logo9}
                alt="un triangle aux trois côtés égaux"
                className="container-img"
              />
              <div className="container-label">Scanner QR</div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}