import React, { useState, useEffect } from "react";
import "./Dashboard.scss";
import HeaderComponent from './header/headerComponent'
import { useHistory } from "react-router-dom";
import { FaCheckCircle, FaSearch, FaUserAlt, FaSyncAlt } from 'react-icons/fa';
import { Logo9 } from './../../assets/images/scanqr.js';
import axiosInstance from '../../services/httpInterceptor'
import { frenchDate } from '../../shared/tools/formatDate'
import Modal from 'react-modal';
import QrReader from 'react-qr-scanner'

const API_URL = process.env.REACT_APP_URL;

export default function PatientDashboard() {
  const history = useHistory();
  const [isQRModalOpen, setisQRModalOpen] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [patientID, setpatientID] = useState("");
  const [benifList, setbenifList] = useState([]);
  const [data, setData] = useState();
  const [isFrontCam, setIsFrontCam] = useState(true);
  const previewStyle = {
    height: 240,
    width: 320,
  }
  useEffect(() => {
  }, []);

  function searchBenif() {
    setisloading(true)
    const url = `${API_URL}/doctors/benif/${patientID}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        setisloading(false)
        setbenifList(result)
        navigateTo()
      });
  }
  function searchBenifByID(id) {
    setisloading(true)
    const url = `${API_URL}/doctors/benif/id/${id}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        console.log()
        setisloading(false)
        setbenifList(result)
      });
  }

  function switchCam() {
    setIsFrontCam(!isFrontCam)
  }
  function benifNavigate(benif) {
    console.log('benif ', benif)
    const benifID = benif._id;
    navigateTo('/patient/user/' + benifID)
  }
  function openQRCodeModal() {

    setisQRModalOpen(true)
  }
  function navigateTo(url) {
    history.push(url);
  }
  function closeModal() {
    setisQRModalOpen(false)
  }
  function handleError(err) {
    console.error(err)
  }
  function handleScan(data) {
    setData(data)
    if (data && data.text) {
      const key = data.text.split(' ');
      if (key[0] === '{DDF093D}') {
        const id = key[1]
        console.log('id ', id)
        closeModal()
        searchBenifByID(id)
        // navigateTo('/patient/benificiaire/resume/' + id)
      }
    }
  }
  return (
    <div>
      <HeaderComponent></HeaderComponent>
      <Modal
        isOpen={isQRModalOpen}
        onRequestClose={closeModal.bind(this)}
        contentLabel="Example Modal"
      >
        <div className="graph__footer">
          Présenter le CODE QR du patient
        </div>

        <QrReader
          delay={1500}
          style={previewStyle}
          onError={handleError}
          onScan={handleScan}
          facingMode={isFrontCam ? 'rear' : 'front'} /// 'rear'
        />
        <div className="btn-action" onClick={(e) => switchCam()}> <FaSyncAlt>  </FaSyncAlt>switch</div>

      </Modal>
      <div className="container-body">
        <div className="container-title">Chercher un profil</div>
        <div className="container-list-benif text-color">
          <div>
            Scanner le code QR du bénéficier ou chercher par son ID
          </div>
          <div className="doctor_flex">

            <div className="doctor_container-widget" onClick={(e) => openQRCodeModal()}>
              <div>
                <img src={Logo9}
                  alt="un triangle aux trois côtés égaux"
                  className="doctor_container-img"
                />
                <div className="doctor_container-label">QR Code</div>
              </div>
            </div>
            <span className="doctor_margin">
              Ou
            </span>

            <div className="doctor_width">
              <input className="doctor_input" value={patientID} onChange={event => setpatientID(event.target.value)} />
              <div className="doctor_icon-container">
                <FaSearch className="doctor_icon" onClick={(e) => searchBenif()}></FaSearch>
              </div>
            </div>
          </div>
          {
            benifList.length > 0 && <div className="doctor_flex">
              <div className="doctor_card">

                <div className="doctor_card-photo">
                  <FaUserAlt
                    className="doctor_card-photo"
                  />
                </div>

                <div className="doctor_card-detail">
                  <div className="doctor_card-title">{benifList[0].first_name + ' ' + benifList[0].last_name}</div>
                  <div className="doctor_card-subtitle">{frenchDate(benifList[0].birth_date)}</div>
                </div>

                <div className="doctor_card-btn" onClick={(e) => benifNavigate(benifList[0])}>
                  Accéder
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    </div>
  );
}