import Keycloak from "keycloak-js";

import axiosInstance from './httpInterceptor'
const _kc = new Keycloak('./keycloak.json');

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc.init({
    onLoad: 'login-required'
  })
    .then((authenticated) => {
      console.log('authenticated ', _kc)
      if (!authenticated) {
        console.log("user is not authenticated..!");
      }
      localStorage.setItem('token', _kc.token);
      localStorage.setItem('refreshToken', _kc.refreshToken);
      localStorage.setItem('authenticated', authenticated);
      localStorage.setItem('profile_corps_medicale', _kc.tokenParsed.profile_corps_medicale);


      if (_kc.tokenParsed.profile_corps_medicale == "SPECIALISTE" || _kc.tokenParsed.profile_corps_medicale == "GENERALISTE" || _kc.tokenParsed.profile_corps_medicale == "SPECIALIST" || _kc.tokenParsed.profile_corps_medicale == "GENERALIST" || _kc.tokenParsed.profile_corps_medicale == "LABORATOIRE")
        addOrUpdateDoctor(_kc, _kc.tokenParsed.profile_corps_medicale);
      else// (_kc.tokenParsed.profile_corps_medicale == "PATIENT")
        addOrUpdatePatient(_kc);
      onAuthenticatedCallback();
    })
    .catch(console.error);
};
function addOrUpdatePatient(_kc) {

  axiosInstance.get(`${process.env.REACT_APP_URL}/patients/filter?email_address=${_kc.tokenParsed.email}`)
    .then(res => {
      if (res.data.length == 0) {
        const data = {
          email_address: _kc.tokenParsed.email,
          last_name: _kc.tokenParsed.family_name,
          first_name: _kc.tokenParsed.given_name,
          phone_number: _kc.tokenParsed.mobile,
          dob: _kc.tokenParsed.dob,
        };

        axiosInstance.post(`${process.env.REACT_APP_URL}/patients`, data)
          .then(response => response.data)
          .then(patient => {
            console.log('patientd ', patient)
            localStorage.setItem('patient', patient._id);
            addOrUpdateUser(patient, 'patients')
          })
      } else {
        localStorage.setItem('patient', res.data[0]._id);
      }
    })
}

function addOrUpdateDoctor(_kc, speciality) {

  axiosInstance.get(`${process.env.REACT_APP_URL}/medecins/filter?email_address=${_kc.tokenParsed.email}`)
    .then(res => {
      if (res.data.length == 0) {
        const data = {
          email_address: _kc.tokenParsed.email,
          last_name: _kc.tokenParsed.family_name,
          first_name: _kc.tokenParsed.given_name,
          phone_number: _kc.tokenParsed.mobile,
          dob: _kc.tokenParsed.dob,
          speciality: "Medecin"
        };

        axiosInstance.post(`${process.env.REACT_APP_URL}/medecins`, data)
          .then(response => response.data)
          .then(patient => {
            localStorage.setItem('patient', patient._id);
            addOrUpdateUser(patient, 'medecins')
          })
      } else {
        console.log('res res res ', res)
        localStorage.setItem('patient', res.data[0]._id);
      }
    })
}

function addOrUpdateUser(patient, type) {
  const data = {
    username: patient.email_address,
    user_type: {
      kind: type,
      item: patient._id
    },
  };
  axiosInstance.post(`${process.env.REACT_APP_URL}/users`, data)
    .then(res => {
      console.log('user ', res);
    })
}
const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

_kc.onTokenExpired = () => {
  _kc.updateToken(5).then((refreshed) => {
    if (refreshed) {
      localStorage.setItem('token', _kc.token);
      localStorage.setItem('refreshToken', _kc.refreshToken);
      localStorage.setItem('authenticated', refreshed);
    } else {
      console.log('not refreshed ' + new Date());
    }
  }).catch(() => {
    console.error('Failed to refresh token ' + new Date());
  });
}

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
};

export default UserService;