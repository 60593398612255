import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import { FaAngleDown, FaBackward, FaPlus, FaEdit, FaTrashAlt } from 'react-icons/fa';
import {
  BrowserRouter as Router,
  useParams
} from "react-router-dom";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { FaCheckCircle, FaRegHourglass, FaAngleDoubleDown } from 'react-icons/fa';
import axiosInstance from '../../../services/httpInterceptor'
import { useHistory } from "react-router-dom";
import OrdonnaceAdd from './OrdonnaceAdd'
import "./mesOrdonnaces.scss";
import HeaderComponent from '../header/headerComponent'
import WarningMessage from '../../../shared/component/WarningMessage'
import DisplayFile from '../commun/displayFile'
import { frenchDate } from '../../../shared/tools/formatDate'
import EmptyList from '../../../shared/component/EmptyList'
import RadioAdd from '../radio/RadioAdd'
import TraitementAdd from '../traitement/TraitementAdd'
import AnalyseAdd from '../analyse/AnalyseAdd'

import AttachementComponenet from '../../shared/AttachementComponenet'
import DentistSVG from "../../../assets/images/svg/dentist.svg";
import DoctorSVG from "../../../assets/images/svg/doctor.svg";
const API_URL = process.env.REACT_APP_URL;

const StatusMapping = {
  Later: 'A venir',
  InProgress: 'En cours',
  Done: 'Terminé',
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const TraitementStatusMapping = {
  2: 'Done',
  1: 'InProgress',
  0: 'Later',
}
export default function MesOrdonnaces() {
  const [isFileOpen, setIsFileOpen] = useState(false);
  const [fileJson, setFileJson] = useState('');
  let { id } = useParams();
  const history = useHistory()
  const classes = useStyles();
  const [sequence, setsequence] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);
  const [filter, setFilter] = React.useState('Medecin');
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [ordonannceList, setOrdonannceList] = React.useState([]);
  const [displayedOrdonannceList, setdisplayedOrdonannceList] = React.useState([]);
  const [ordonnance, setOrdonnance] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [value, setValue] = React.useState(0);

  //inside blob
  const [insideBlocNav, setinsideBlocNav] = React.useState(0);
  const [isOpenInsideRadio, setisOpenInsideRadio] = React.useState(false);
  const [isOpenInsideTrait, setisOpenInsideTrait] = React.useState(false);
  const [isOpenInsideAnalyse, setisOpenInsideAnalyse] = React.useState(false);

  const [radio, setradio] = useState({});
  const [analyse, setanalyse] = useState({});
  const [traitement, settraitement] = useState({});
  const [RadioInsideSequence, setRadioInsideSequence] = React.useState(0);
  const [TraiInsideSequence, setTraiInsideSequence] = React.useState(0);
  const [AnalyseInsideSequence, setAnalyseInsideSequence] = React.useState(0);

  const [TraitInsideList, setTraitInsideList] = React.useState([]);
  const [RadioInsideList, setRadioInsideList] = React.useState([]);
  const [AnalyseInsideList, setAnalyseInsideList] = React.useState([]);




  useEffect(() => {
    const url = `${API_URL}/benificiares/${id}?patient=${localStorage.patient}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        const benif = result[0];
        setFirstName(benif.first_name)
        setLastName(benif.last_name)
      }
      );
    refreshList();
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setTraitInsideList([])
    setAnalyseInsideList([])
    setRadioInsideList([])
    setinsideBlocNav(0)
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangeFilter = (panel) => (event, isExpanded) => {
    setFilter(panel);
    const filteredData = ordonannceList.filter(data => {
      return data.ordonnance_status === TraitementStatusMapping[value] && data.consultation.medecin.speciality === panel
    })
    setdisplayedOrdonannceList(filteredData)
  };

  function goBack() {
    navigateTo('/patient/user/' + id)
  }
  function navigateTo(url) {
    history.push(url);
  }

  function refreshList() {
    const url = `${API_URL}/ordonnances/benif/${id}?patient=${localStorage.patient}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        setsequence(result.length)
        setOrdonannceList(result);
        const filteredData = result.filter(data =>
          data.ordonnance_status === TraitementStatusMapping[value] && data.consultation.medecin.speciality === filter)
        setdisplayedOrdonannceList(filteredData)

      }
      );
  }
  function addOrdonnance() {
    setOrdonnance({})
    setIsOpen(true)
  }
  function onChange(value) {
    setIsOpen(false)
    refreshList();
  }

  function onConfirm(value) {
    setIsOpenWarning(false);
    const url = `${API_URL}/ordonnances/${ordonnance._id}?patient=${localStorage.patient}`;
    axiosInstance.delete(url).then(response => response.data)
      .then((result) => {
        refreshList()
      }
      );
    refreshList()
  }
  function supprimerOrdonnance(ordonnance) {
    setOrdonnance(ordonnance)
    setIsOpenWarning(true)
  }
  function onCloseWarning(value) {
    setIsOpenWarning(false)
  }

  function editOrdonnance(ordonnance) {
    setOrdonnance(ordonnance)
    setIsOpen(true)
  }
  function downloadFile(file) {
    setFileJson(file)
    setIsFileOpen(true)
  }
  function onChangeFile(value) {
    setIsFileOpen(false)
  }

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    const filteredData = ordonannceList.filter(data =>
      data.ordonnance_status === TraitementStatusMapping[newValue] && data.consultation.medecin.speciality === filter)
    setdisplayedOrdonannceList(filteredData)
  };
  function renderStatus(ordonnance) {
    return (

      <div className='all_width'>
        {ordonnance.ordonnance_status === 'Done' &&
          <Typography className="padding2 right-status status-done">
            {StatusMapping[ordonnance.ordonnance_status]}<FaCheckCircle></FaCheckCircle>
          </Typography>}
        {ordonnance.ordonnance_status === 'InProgress' && <Typography className="padding2 right-status status-progress">
          {StatusMapping[ordonnance.ordonnance_status]}<FaRegHourglass></FaRegHourglass>
        </Typography>}

        {ordonnance.ordonnance_status === 'Later' && <Typography className="padding2 right-status status-todo">
          {StatusMapping[ordonnance.ordonnance_status]}<FaRegHourglass></FaRegHourglass>
        </Typography>}</div>

    )
  }

  function onChangeAddRadio(value, ordoId) {
    setisOpenInsideRadio(false)
    listRadioByOrdo(ordoId)
  }
  function onChangeAddTrait(value, ordoId) {
    setisOpenInsideTrait(false)
    listTraitByOrdo(ordoId)
  }
  function onChangeAddAnalyse(value, ordoId) {
    setisOpenInsideAnalyse(false)
    listAnalyseByOrdo(ordoId)
  }

  function listRadioByOrdo(ordoId) {
    const url = `${API_URL}/radios/benif/${id}?patient=${localStorage.patient}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        setRadioInsideSequence(result.length)
        const filteredData = result.filter(data => {
          return data.ordonnance && data.ordonnance._id == ordoId
        })
        setRadioInsideList(filteredData);
        setinsideBlocNav(3)
      });
  }

  function listTraitByOrdo(ordoId) {
    const url = `${API_URL}/traitements/benif/${id}?patient=${localStorage.patient}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        setTraiInsideSequence(result.length)
        const filteredData = result.filter(data => {
          return data.ordonnance && data.ordonnance._id == ordoId
        })
        setTraitInsideList(filteredData);
        setinsideBlocNav(1)
      });
  }

  function listAnalyseByOrdo(ordoId) {

    const url = `${API_URL}/analyses/benif/${id}?patient=${localStorage.patient}`;
    axiosInstance.get(url).then(response => response.data)
      .then((result) => {
        setAnalyseInsideSequence(result.length)
        const filteredData = result.filter(data => {
          return data.ordonnance && data.ordonnance._id == ordoId
        })
        setAnalyseInsideList(filteredData);
        setinsideBlocNav(2)
      });
  }
  function updateInsideRadio(ordonnanceTemp) {
    setisOpenInsideRadio(true)
    const ordonnance = {
      ordonnance: {
        _id: ordonnanceTemp._id,
        ordonnance_name: ordonnanceTemp.ordonnance_name
      }
    }
    setradio(ordonnance)
  }
  function updateInsideAnalyse(ordonnanceTemp) {
    setisOpenInsideAnalyse(true)
    const ordonnance = {
      ordonnance: {
        _id: ordonnanceTemp._id,
        ordonnance_name: ordonnanceTemp.ordonnance_name
      }
    }
    setanalyse(ordonnance)
  }
  function updateInsideTrait(ordonnanceTemp) {
    setisOpenInsideTrait(true)
    const ordonnance = {
      ordonnance: {
        _id: ordonnanceTemp._id,
        ordonnance_name: ordonnanceTemp.ordonnance_name
      }
    }
    settraitement(ordonnance)
  }

  function updateInsideTrait2(ordonnance) {
    setisOpenInsideTrait(true)
    settraitement(ordonnance)
  }

  function updateInsideAnalyse2(ordonnance) {
    setisOpenInsideAnalyse(true)
    setanalyse(ordonnance)
  }

  function updateInsideRadio2(ordonnance) {
    setisOpenInsideRadio(true)
    setradio(ordonnance)
  }

  function updateAttachement(file, consultation) {
    const analyseId = consultation._id;
    sendFiles(analyseId, [file]);
  }
  function sendFiles(analyseId, fileList) {
    if (fileList) {

      const formData = new FormData();
      for (var x = 0; x < fileList.length; x++) {
        formData.append("file", fileList[x], fileList[x].name);
      }
      const url = `${API_URL}/ordonnances/${analyseId}/benif/${id}/upload/${fileList[0].name}?patient=${localStorage.patient}`
      axiosInstance.post(url, formData).then(response => response.data)
        .then((result) => {
          refreshList();
        }
        );
    }

  }
  return (
    <div className="container-wrapper">
      <HeaderComponent></HeaderComponent>
      <div className="container-return-action" onClick={(e) => goBack()}>
        <FaBackward>  </FaBackward> {'  '} &nbsp;&nbsp;Retour à l'accueil
      </div>

      {
        isOpenInsideRadio ?
          <RadioAdd
            sequence={RadioInsideSequence}
            isOpen={isOpenInsideRadio}
            onChange={onChangeAddRadio}
            benif={id}
            radio={radio}
          ></RadioAdd>
          : null
      }
      {
        isOpenInsideTrait ?
          <TraitementAdd
            sequence={TraiInsideSequence}
            isOpen={isOpenInsideTrait}
            onChange={onChangeAddTrait}
            benif={id}
            traitement={traitement}
          ></TraitementAdd>
          : null
      }
      {
        isOpenInsideAnalyse ?
          <AnalyseAdd
            sequence={AnalyseInsideSequence}
            isOpen={isOpenInsideAnalyse}
            onChange={onChangeAddAnalyse}
            benif={id}
            analyse={analyse}
          ></AnalyseAdd>
          : null
      }

      {
        isOpen ?
          <OrdonnaceAdd
            sequence={sequence}
            onChange={onChange}
            isOpen={isOpen}
            ordonnance={ordonnance}
            benif={id}
          ></OrdonnaceAdd>
          : null
      }
      {
        isFileOpen ?
          <DisplayFile
            onChangeFile={onChangeFile}
            isOpen={isFileOpen}
            fileJson={fileJson}
            benif={id}
          ></DisplayFile>
          : null
      }
      <WarningMessage
        onCloseWarning={onCloseWarning}
        isOpenWarning={isOpenWarning}
        onConfirm={onConfirm}>
      </WarningMessage>
      <div className="container-body">

        <div className="container-right-actions" onClick={(e) => addOrdonnance()}>
          <div className="container-right-actions_bloc-left">
            <div className="container-right-actions_bloc-left_inside">
              <FaPlus>  </FaPlus>
            </div></div>Ajouter une Ordonnance
          {/* <div className="btn-action"> <FaPlus>  </FaPlus>Prendre RDV</div> */}
        </div>
        <div className="container-title">
          Detail des ordonnances
        </div>
        <div className="container-filters-top">
          <div className={filter === 'Medecin' ? "container-filter-top-actif" : "container-filter-top"}
            onClick={handleChangeFilter('Medecin')}><img className="img-small" src={DoctorSVG} alt="Your SVG" />Médecin</div>
          <div className={filter === 'Dentiste' ? "container-filter-top-actif" : "container-filter-top"}
            onClick={handleChangeFilter('Dentiste')}><img className="img-small" src={DentistSVG} alt="Your SVG" />Dentiste</div>
        </div>
        <Tabs
          value={value}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          centered
        >

          <Tab label="A venir" icon={<FaRegHourglass />} style={{ color: 'red' }} />
          <Tab label="En Cours" icon={<FaCheckCircle />} style={{ color: 'orange' }} />
          <Tab label="Terminé" icon={<FaCheckCircle />} style={{ color: 'green' }} />
        </Tabs>

        <div className={classes.root}>
          {
            displayedOrdonannceList.length === 0 && <EmptyList></EmptyList>
          }
          {
            displayedOrdonannceList.map((ordonnance, index) => {
              return (
                <Accordion expanded={expanded === ordonnance._id} onChange={handleChange(ordonnance._id)}>
                  <AccordionSummary
                    expandIcon={<FaAngleDown />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className='accordion__title'>{frenchDate(ordonnance.date_rdv)}</Typography>
                    <Typography className='accordion__subtitle'>
                      Dr. {ordonnance.consultation.medecin.last_name + ' ' + ordonnance.consultation.medecin.first_name}

                    </Typography>
                    <Typography className='accordion__subtitle1'> &nbsp;{ordonnance.consultation.consultation_maladie ? ordonnance.consultation.consultation_maladie : ''}
                      &nbsp;{ordonnance.consultation.consultation_name ? ordonnance.consultation.consultation_name : ''}</Typography>

                    {/* {renderStatus(ordonnance)} */}
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='all_width'>

                      <div className="lines__footer">
                        <div className="lines__footer-action" onClick={(e) => supprimerOrdonnance(ordonnance)}> <FaTrashAlt>  </FaTrashAlt>Supprimer</div>
                        <div className="lines__footer-action" onClick={(e) => editOrdonnance(ordonnance)}> <FaEdit>  </FaEdit>Modifier</div>
                      </div>
                      <div className="lines">
                        <div className="lines__line"> <div className="lines__title">Titre</div><div className="lines__desc">{ordonnance.ordonnance_name}</div></div>
                        <div className="lines__line"> <div className="lines__title">Docteur</div><div className="lines__desc">{ordonnance.consultation.medecin.last_name + ' ' + ordonnance.consultation.medecin.first_name}</div></div>
                        <div className="lines__line"> <div className="lines__title">Consultation</div><div className="lines__desc">{ordonnance.consultation.consultation_name}</div></div>
                        <div className="lines__line"> <div className="lines__title">Maladie</div><div className="lines__desc">{ordonnance.consultation.consultation_maladie}</div></div>
                        <div className="lines__line"> <div className="lines__title">Date de l'ordonnance</div><div className="lines__desc">{frenchDate(ordonnance.date_rdv)}</div></div>
                        {/* <div className="lines__line"> <div className="lines__title">prix</div><div className="lines__desc">{ordonnance.price}</div></div> */}
                        <div className="lines__line"> <div className="lines__title">Commentaire medecin</div><textarea className="lines__desc" value={ordonnance.comment_medecin}></textarea></div>
                        <div className="lines__line"> <div className="lines__title">Commentaire patient</div><textarea className="lines__desc" value={ordonnance.comment}></textarea></div>
                        <div className="lines__line"> <div className="lines__title">Document</div>
                          {
                            ordonnance.attachements.length > 0 ?
                              ordonnance.attachements.map((exam, index) => {
                                return (<div className="download-file lines__desc" onClick={(e) => downloadFile(exam)}> Télécharger l'ordonnance </div>)
                              })
                              : <AttachementComponenet saveFile={(e) => updateAttachement(e, ordonnance)}></AttachementComponenet>
                          }
                        </div>
                      </div>


                      <div className="flex">

                        <div className="lines__title-clicked" onClick={(e) => listTraitByOrdo(ordonnance._id)}><FaAngleDoubleDown></FaAngleDoubleDown>{' '} Traitement</div>
                        <div className="lines__title-clicked" onClick={(e) => listAnalyseByOrdo(ordonnance._id)}><FaAngleDoubleDown></FaAngleDoubleDown>{' '}  Biologie</div>
                        <div className="lines__title-clicked" onClick={(e) => listRadioByOrdo(ordonnance._id)}><FaAngleDoubleDown></FaAngleDoubleDown>{' '}  Imagerie</div>
                      </div>
                      {
                        insideBlocNav == 1 &&
                        <div className="bloc-inside">
                          {
                            <div>
                              <div className="lines__line"> <div className="lines__title-clicked" onClick={(e) => updateInsideTrait(ordonnance)}>{'+ '} Ajouter Traitement</div></div>

                              {TraitInsideList.map((ordonnance, index) => {
                                return (
                                  <div className="bloc-inside__item" onClick={(e) => updateInsideTrait2(ordonnance)}>
                                    <span>{ordonnance.traitement_name} </span>
                                  </div>
                                )
                              })}
                            </div>

                          }
                        </div>
                      }
                      {
                        insideBlocNav == 2 &&
                        <div className="bloc-inside">
                          {
                            <div>
                              <div className="lines__line"> <div className="lines__title-clicked" onClick={(e) => updateInsideAnalyse(ordonnance)}>{'+ '} Ajouter Biologie</div></div>
                              {AnalyseInsideList.map((ordonnance, index) => {
                                return (
                                  <div className="bloc-inside__item" onClick={(e) => updateInsideAnalyse2(ordonnance)}>
                                    <span>{ordonnance.analyse_name} </span>
                                  </div>
                                )
                              })}
                            </div>

                          }
                        </div>
                      }
                      {
                        insideBlocNav == 3 &&
                        <div className="bloc-inside">
                          {
                            <div>
                              <div className="lines__line"> <div className="lines__title-clicked" onClick={(e) => updateInsideRadio(ordonnance)}>{'+ '} Ajouter Imagerie</div></div>
                              {RadioInsideList.map((ordonnance, index) => {
                                return (
                                  <div className="bloc-inside__item" onClick={(e) => updateInsideRadio2(ordonnance)}>
                                    <span>{ordonnance.radio_name} </span>
                                  </div>
                                )
                              })}
                            </div>

                          }
                        </div>
                      }
                    </div>
                  </AccordionDetails>
                </Accordion>

              )
            })
          }
        </div>
      </div>
    </div>
  );
}