import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Modal from 'react-modal';
import { FaTrash } from 'react-icons/fa';
import { CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import axiosInstance from '../../../services/httpInterceptor'
import Switch from '@material-ui/core/Switch';
import "./HospitalisationAdd.scss";
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '470px',
    width: '500px',
    maxWidth: '80%',
    maxHeight: '80%',
    padding: 0
  }
};
const API_URL = process.env.REACT_APP_URL;

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
export default function HospitalisationAdd(props) {

  const [loading, setLoading] = useState(false);
  const hospitalisationResult = [];
  const [status, setStatus] = useState(props.hospitalisation.hospitalisation_status);
  const [comment, setComment] = useState(props.hospitalisation.comment);
  const [commentHospitalier, setCommentHospitalier] = useState(props.hospitalisation.comment_hospitalier);
  const [centre, setCentre] = useState(props.hospitalisation.centre);
  const [fileList, setFileList] = useState(props.hospitalisation.attachements);
  const [startDate, setStartDate] = useState(props.hospitalisation.start_date || (new Date()).toISOString().substr(0, 10));
  const [endDate, setEndDate] = useState(props.hospitalisation.end_date || (new Date()).toISOString().substr(0, 10));
  const [dateOpertation, setDateOpertation] = useState(props.hospitalisation.date_opertation || (new Date()).toISOString().substr(0, 10));
  const [hospitalisationService, setHospitalisationService] = useState(props.hospitalisation.hospitalisation_service);
  const [addresse, setAddresse] = useState(props.hospitalisation.addresse);
  const [phoneNumber, setPhoneNumber] = useState(props.hospitalisation.phone_number);
  const [fax, setFax] = useState(props.hospitalisation.fax);
  const [emailAddress, setEmailAddress] = useState(props.hospitalisation.email_address);
  const [contacts, setContacts] = useState(props.hospitalisation.contacts);
  const [operation, setOperation] = useState(props.hospitalisation.operation);
  const [maladie, setMaladie] = useState(props.hospitalisation.maladie);
  const [duration, setDuration] = useState(props.hospitalisation.duration);


  const classes = useStyles();
  function closeModal() {
    props.onChange(true);
  }

  function handleSubmit(event) {
    setLoading(true);
    if (props.hospitalisation._id) {
      const dataToSend = {
        _id: props.hospitalisation._id,
        start_date: startDate,
        end_date: endDate,
        hospitalisation_status: status,
        hospitalisation_service: hospitalisationService,
        addresse: addresse,
        phone_number: phoneNumber,
        fax: fax,
        email_address: emailAddress,
        contacts: contacts,
        operation: operation,
        date_opertation: dateOpertation,
        comment: comment,
        duration,
        comment_hospitalier: commentHospitalier,
        centre,
        maladie

      }
      const url = `${API_URL}/hospitalisations/benif/${props.benif}?patient=${localStorage.patient}`
      axiosInstance.post(url, dataToSend).then(response => response.data)
        .then((result) => {
          const hospitalisationId = result._id;
          sendFiles(hospitalisationId);
        }
        );
    } else {
      console.log(duration)
      const dataToSend = {
        _id: props.hospitalisation._id,
        start_date: startDate,
        end_date: endDate,
        hospitalisation_status: status,
        hospitalisation_service: hospitalisationService,
        addresse: addresse,
        phone_number: phoneNumber,
        fax: fax,
        email_address: emailAddress,
        contacts: contacts,
        operation: operation,
        date_opertation: dateOpertation,
        comment: comment,
        duration,
        comment_hospitalier: commentHospitalier,
        centre,
        maladie
      }
      const url = `${API_URL}/hospitalisations/benif/${props.benif}?patient=${localStorage.patient}`
      axiosInstance.post(url, dataToSend).then(response => response.data)
        .then((result) => {
          const hospitalisationId = result._id;
          sendFiles(hospitalisationId);
        });
    }
  }

  function sendFiles(hospitalisationId) {
    if (fileList) {
      if (fileList && fileList.length > 0 && fileList[0]._id) { closeModal(); }
      else if (fileList.length == 0) { closeModal(); }
      else {
        const formData = new FormData();
        for (var x = 0; x < fileList.length; x++) {
          formData.append("file", fileList[x], fileList[x].name);
        }
        const url = `${API_URL}/hospitalisations/${hospitalisationId}/benif/${props.benif}/upload/${fileList[0].name}?patient=${localStorage.patient}`
        axiosInstance.post(url, formData).then(response => response.data)
          .then((result) => {
            closeModal();
          }
          );
      }
    } else {
      closeModal();
    }

  }
  function deleteFile(e, file, index) {
    setFileList(fileList.filter(item => item.name !== file.name));
  }
  function uploadFile(event) {
    const file = event[0];
    if (file.size > 5000000) {
      toast.error('fichier trés large');
      return
    }
    const array = []
    for (let i = 0; i < event.length; i++) {
      array.push(event[i])
    }
    setFileList(array);
  }

  function handleChangeStatus(event) {
    setStatus(event.target.value);
  };

  function handleOperation(event) {
    setOperation(event.target.checked);
  };
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={closeModal.bind(this)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal__header">
        <div className="modal__header-title">
          {
            !props.hospitalisation._id ? 'Nouveau hospitalisation' : `Mise à jour d'hospitalisation`
          }
        </div>
        <div className="modal__header-close">
        </div>
      </div>

      <div className="modal__body">

        <div className="modal__body-description">
          Les champs obligatoires sont suivi par une *
        </div>


        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <form className={classes.form} >
              <div className={'content-modal'}>
                {
                  props.hospitalisation._id &&
                  < div >
                    Statuts:&nbsp;&nbsp;
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={status}
                      onChange={handleChangeStatus}
                    >
                      <MenuItem value={'Later'}>A venir</MenuItem>
                      <MenuItem value={'InProgress'}>En cours</MenuItem>
                      <MenuItem value={'Done'}>Terminé</MenuItem>
                    </Select>
                  </div>
                }

                <TextField id="date" label="Date début*" type="date" value={startDate}
                  onChange={event => setStartDate(event.target.value)}
                  InputLabelProps={{ shrink: true }} />
                <TextField id="date" label="Date fin*"
                  className={"margin-left"}
                  type="date" value={endDate}
                  onChange={event => setEndDate(event.target.value)}
                  InputLabelProps={{ shrink: true }} />


                <TextField margin="normal" fullWidth label="Centre hospitalisation" name="lastName" value={centre} onChange={event => setCentre(event.target.value)} />
                <TextField margin="normal" fullWidth label="Maladie" name="lastName" value={maladie} onChange={event => setMaladie(event.target.value)} />
                <TextField margin="normal" fullWidth label="Service" name="lastName" value={hospitalisationService} onChange={event => setHospitalisationService(event.target.value)} />
                <TextField margin="normal" fullWidth label="Addresse" name="lastName" value={addresse} onChange={event => setAddresse(event.target.value)} />
                <TextField margin="normal" fullWidth label="Tel" name="lastName" value={phoneNumber} onChange={event => setPhoneNumber(event.target.value)} />
                <TextField margin="normal" fullWidth label="Fax" name="lastName" value={fax} onChange={event => setFax(event.target.value)} />
                <TextField margin="normal" fullWidth label="Email" name="lastName" value={emailAddress} onChange={event => setEmailAddress(event.target.value)} />
                <TextField margin="normal" fullWidth label="Contact" name="lastName" value={contacts} onChange={event => setContacts(event.target.value)} />

                <div style={{
                  display: 'flex',
                  margin: '5px 0',
                  alignItems: 'center'
                }}>
                  <span style={{ width: '140px' }}>

                    Opération:
                  </span>
                  Non
                  <Switch
                    checked={operation == 'true' || operation === true}
                    onChange={handleOperation} />
                  Oui
                </div>

                <TextField margin="normal" fullWidth label="Durée du séjour" type="text" value={duration}
                  onChange={event => setDuration(event.target.value)} />
                <TextField id="date" label="Date opération" type="date" value={dateOpertation}
                  onChange={event => setDateOpertation(event.target.value)}
                  InputLabelProps={{ shrink: true }} />

                <TextField margin="normal" fullWidth label="Commentaire Patient" name="firstName" value={comment} onChange={event => setComment(event.target.value)} />
                <TextField margin="normal" fullWidth label="Compte rendu" name="firstName" value={commentHospitalier} onChange={event => setCommentHospitalier(event.target.value)} />
                {
                  (fileList && fileList.length > 0) ?
                    (fileList).map((item, index) => {
                      return (<div> {item.name} <FaTrash onClick={(e) => deleteFile(e, item, index)}></FaTrash></div>);
                    })
                    : null
                }
                <div className={'drag-container'}>
                  <input
                    type="file"
                    onChange={(e) => uploadFile(e.target.files)} />

                  <span>Parcourir mon ordinateur (Max 5M)</span>
                </div>
              </div>
            </form>
          </div>
        </Container>
      </div >
      <div className="modal__footer">
        <Button
          variant="contained"
          color="default"
          className={classes.submit}
          onClick={closeModal}
        > Annuler </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
          disabled={!startDate || !endDate || loading}
        >
          {loading && <CircularProgress size={14} />} {
            !props.hospitalisation._id ? 'Ajouter' : 'Mise à jour'
          } </Button>

      </div>
    </Modal >
  );
}