import axios from 'axios';
import promise from 'promise';

import { toast } from 'react-toastify';
import UserService from './UserService.js'
// Add a request interceptor 
var axiosInstance = axios.create();

axiosInstance.interceptors.request.use(function (config) {
    // Do something before request is sent 
    //If the header does not contain the token and the url not public, redirect to login  
    var accessToken = localStorage.token;

    //if token is found add it to the header
    if (accessToken) {
        if (config.method !== 'OPTIONS') {
            config.headers.authorization = 'Bearer ' + accessToken;
        }
    }
    return config;
}, function (error) {
    // Do something with request error 
    return promise.reject(error);
});
//Add a response interceptor

axiosInstance.interceptors.response.use((response) => {
    return response
}, function (error) {
    if (!error.response) {
        // toast.error(error.message);
    } else {
        const originalRequest = error.config;
        console.log('error.response ', error.response)
        if (error.response.status === 403 && error.response.data === "Access denied") {
            originalRequest._retry = true;
            // return axios.post('http://localhost:8080/realms/medicale/protocol/openid-connect/token',
            //     {
            //         client_id: 'front-mysihati',
            //         grant_type: 'refresh_token',
            //         "refresh_token": refreshToken
            //     })
            //     .then(res => {

        }
    }
    return Promise.reject(error);
});

export default axiosInstance;