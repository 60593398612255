export const Maladie_list = [
  {
    id: 1,
    name: "MALADIE1"
  }, {
    id: 2,
    name: "MALADIE2"
  }, {
    id: 3,
    name: "MALADIE3"
  }, {
    id: 4,
    name: "MALADIE4"
  }, {
    id: 5,
    name: "MALADIE5"
  }, {
    id: 6,
    name: "MALADIE6"
  }, {
    id: 7,
    name: "MALADIE7"
  }, {
    id: 8,
    name: "MALADIE8"
  }, {
    id: 9,
    name: "MALADIE9"
  }, {
    id: 10,
    name: "MALADIE10"
  }
]