import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  useParams
} from "react-router-dom";
import "./CodeQR.scss";
import HeaderComponent from '../header/headerComponent'
import QrReader from 'react-qr-scanner'
import { useHistory } from "react-router-dom";
import { FaAngleDown, FaBackward, FaCalendarPlus, FaSyncAlt } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function CodeQR() {

  let { id } = useParams();
  const history = useHistory()
  const [data, setData] = useState();
  const [isFrontCam, setIsFrontCam] = useState(true);
  const classes = useStyles();

  function handleScan(data) {
    setData(data)
    if (data && data.text) {
      const key = data.text.split(' ');
      if (key[0] === '{DDF093D}') {
        const id = key[1]
        navigateTo('/patient/benificiaire/resume/' + id)
      }
    }
  }

  function navigateTo(url) {
    history.push(url);
  }
  function handleError(err) {
    console.error(err)
  }
  const previewStyle = {
    height: 240,
    width: 320,
  }

  function goBack() {
    navigateTo('/patient/user/' + id)
  }
  function switchCam() {
    setIsFrontCam(!isFrontCam)
  }
  return (
    <div className="container-wrapper">
      <HeaderComponent></HeaderComponent>
      <div className="container-body">
        <div className="container-return-action" onClick={(e) => goBack()}>
          <FaBackward>  </FaBackward> {'  '} &nbsp;&nbsp;Retour à l'accueil
        </div>
        <div className="container-title">
          Scanner Code Qr
        </div>
        <div className="container-subtitle">

        </div>
        <div>
          <QrReader
            delay={1500}
            style={previewStyle}
            onError={handleError}
            onScan={handleScan}
            facingMode={isFrontCam ? 'rear' : 'front'} /// 'rear'
          />

          <div className="btn-action" onClick={(e) => switchCam()}> <FaSyncAlt>  </FaSyncAlt>switch</div>
        </div>
      </div>
    </div>
  );
}